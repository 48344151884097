import React from 'react';
import { useTheme } from 'react-jss';
import { IconError, IconNotification } from 'assets/icons';
import './tooltip.css';

function TooltipComponent({ className: classNameProp, content, message, onClick, type, position }) {
    const theme = useTheme();
    if (!message) {
        return null;
    }
    const className = ['dataro-tooltip-container', classNameProp ? classNameProp : ''].join(' ');

    let Icon;
    let color;
    switch (type) {
        case 'error':
            Icon = IconError;
            color = theme.color.error;
            break;
        case 'info':
            Icon = IconNotification;
            color = theme.color.sidebarIconColor;
            break;
        default:
            Icon = IconNotification;
            color = theme.color.sidebarIconColor;
    }

    if (content) {
        return (
            <div className={className} onClick={onClick}>
                {content}
                <TooltipMessage color={color} message={message} />
            </div>
        );
    }

    return (
        <div className={className} onClick={onClick}>
            <Icon height={14} width={14} />
            <TooltipMessage color={color} message={message} position={position} />
        </div>
    );
}

function TooltipMessage({ color, message, position }) {
    const classNameContainer = ['dataro-tooltip', `dataro-tooltip-${position}`].join(' ');
    const classNameArrow = ['dataro-tooltip-arrow', `dataro-tooltip-arrow-${position}`].join(' ');
    return (
        <div className={classNameContainer}>
            <div className='dataro-tooltip-content' style={{ backgroundColor: color }}>
                <span>{message}</span>
            </div>
            <div
                className={classNameArrow}
                style={{ borderBottomColor: color, borderTopColor: color }}
            ></div>
        </div>
    );
}

TooltipComponent.defaultProps = {
    position: 'top'
};

export default TooltipComponent;
