import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import CheckboxComponent from './CheckboxComponent';

function FormCheckboxComponent({
    label,
    name,
    required,
    validate: validateProp,
    defaultValue,
    noSelectableLabel,
    ...props
}) {
    const { errors, formState, register, setValue, getValues } = useFormContext();
    const isTouched = formState.touched[name] || formState.isSubmitted;
    const fieldError = errors[name];
    const errorMessage = isTouched && fieldError && fieldError.message;
    // TODO: check if default values is fixed in next patch https://github.com/react-hook-form/react-hook-form/issues/2308

    useEffect(() => {
        if (defaultValue && !isTouched) {
            setValue(name, defaultValue);
        }
    }, [defaultValue, isTouched, name, setValue]);

    const validate = {
        ...validateProp,
        isRequired: (value) => {
            if (required) {
                return value === 'true' || required;
            }
        }
    };

    return (
        <div>
            <CheckboxComponent
                type='text'
                onChange={(e) =>
                    setValue && setValue(name, e.target.checked, { shouldValidate: true })
                }
                name={name}
                id={name}
                defaultValue={defaultValue}
                label={label}
                noSelectableLabel={noSelectableLabel}
                ref={register({
                    required,
                    validate
                })}
                errorMessage={errorMessage}
                checked={getValues()[name] === 'true'}
                {...props}
            />
        </div>
    );
}

export default FormCheckboxComponent;
