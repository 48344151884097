import React, { useEffect } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Route, Switch, Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { Column } from 'simple-flexbox';
import FooterComponent from 'components/FooterComponent';
import useAddinData from 'hooks/useAddinData';
import DataroTile from './DataroTile';
import NotIntegrated from './NotIntegrated';
import NoData from './NoData';
import NotReady from './NotReady';
import ErrorMessage from './ErrorMessage';

const useStyles = createUseStyles({
    innerContainer: {
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        maxWidth: 800
    }
});

function Routes() {
    const { push } = useHistory();
    const theme = useTheme();
    const classes = useStyles({ theme });
    const isRouteNotIntegrated = useRouteMatch('/not-integrated');
    const {
        error,
        isIntegrated,
        isLoading,
        propensities,
        connectionCompleted,
        userData,
        connectUser,
        setError,
        connectUrl
    } = useAddinData();
    const containerStyle = { maxWidth: 800, padding: '40px 10px', backgroundColor: '#FFFFFF' };
    if (isRouteNotIntegrated) {
        containerStyle.padding = '20px 10px';
    }

    useEffect(() => {
        if (!isLoading) {
            if (error) {
                return push('/error');
            }
            if (connectionCompleted) {
                return push('/not-ready');
            }
            if (!isIntegrated) {
                return push('/not-integrated');
            }
            if (!propensities || propensities.length === 0) {
                return push('/empty-data');
            }
            return push('/dataro-tile');
        }
    }, [error, isIntegrated, isLoading, connectionCompleted, propensities, push]);

    if (isLoading) {
        return <p>Loading...</p>;
    }

    return (
        <Column style={containerStyle}>
            <div className={classes.innerContainer}>
                <Switch>
                    <Route
                        path='/dataro-tile'
                        render={() => <DataroTile propensities={propensities} />}
                    />
                    <Route
                        path='/not-integrated'
                        render={() => (
                            <NotIntegrated userData={userData} connectUser={connectUser} />
                        )}
                    />
                    <Route path='/empty-data' render={() => <NoData />} />
                    <Route path='/not-ready' render={() => <NotReady connectUrl={connectUrl} />} />
                    <Route
                        path='/error'
                        render={() => <ErrorMessage error={error} setError={setError} />}
                    />
                    <Route path='/loading' render={() => <p>Loading...</p>} />
                    <Redirect to='/loading' />
                </Switch>
            </div>
            <FooterComponent />
        </Column>
    );
}

export default Routes;
