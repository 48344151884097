import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';

function FormComponent({ children, defaultValues, onSubmit }) {
    const methods = useForm({
        defaultValues,
        mode: 'onChange'
    });

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>{children}</form>
        </FormProvider>
    );
}

export default FormComponent;
