import React, { forwardRef } from 'react';
import { Column } from 'simple-flexbox';
import TooltipComponent from 'components/tooltip/TooltipComponent';
import './input.css';

function InputComponent(
    {
        className,
        label,
        name,
        type = 'text',
        placeholder,
        errorMessage,
        showErrorMessageBelow,
        ...otherProps
    },
    ref
) {
    const containerClassName = [
        'input-text-container',
        className,
        errorMessage ? 'input-text-container-error' : ''
    ].join(' ');
    return (
        <Column className={containerClassName}>
            {label && <label htmlFor={name}>{label}</label>}
            <input
                type={type || 'text'}
                placeholder={placeholder}
                autoComplete='off'
                ref={ref}
                name={name}
                {...otherProps}
            />
            {errorMessage && (
                <>
                    {!showErrorMessageBelow ? (
                        <div style={{ position: 'relative' }}>
                            <div
                                style={{
                                    position: 'absolute',
                                    right: 20,
                                    bottom: 18
                                }}
                            >
                                <TooltipComponent type='error' message={errorMessage} />
                            </div>
                        </div>
                    ) : (
                        <span className='input-text-container-error-message'>{errorMessage}</span>
                    )}
                </>
            )}
        </Column>
    );
}

export default forwardRef(InputComponent);
