import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import CardComponent from 'components/CardComponent';

const useStyles = createUseStyles({
    container: {
        display: 'grid',
        'grid-template-columns': 'repeat(auto-fill, minmax(200px, 1fr))',
        'grid-gap': '26px',
        padding: 30,
        marginTop: 20
    }
});

const CHANNELS = {
    0: 'Email',
    1: 'Mail',
    2: 'Phone',
    3: 'SMS'
};

function DataroTile({ propensities }) {
    const theme = useTheme();
    const classes = useStyles({ theme });

    function parseScore(item) {
        const { currencySymbol, prediction, score, y_var } = item;
        if (score === undefined) {
            return null;
        }

        if (y_var === 'y_channel_rec') {
            const scoreRound = Math.round(score);
            return CHANNELS[scoreRound];
        }
        if (y_var === 'y_meta_ask_amount') {
            const score = prediction;
            return `${currencySymbol}${score}`;
        }

        const value = parseFloat(score * 100);
        if (value < 1) {
            return (
                <>
                    {`<1`}
                    <sup style={{ fontSize: 24, lineHeight: '27px' }}>%</sup>
                </>
            );
        }
        if (value > 99) {
            return (
                <>
                    {`>99`}
                    <sup style={{ fontSize: 24, lineHeight: '27px' }}>%</sup>
                </>
            );
        }
        return (
            <>
                {`${+parseFloat(score * 100).toFixed(0)}`}
                <sup style={{ fontSize: 24, lineHeight: '27px' }}>%</sup>
            </>
        );
    }

    function getTooltip(propensity) {
        const action = propensity.action;

        const hasData = propensity.score !== undefined;
        let text = `How likely will this donor ${action}`;

        if (propensity.y_var === 'y_channel_rec') {
            return propensity.name;
        }
        if (propensity.y_var === 'y_meta_ask_amount') {
            return propensity.name;
        }

        if (!hasData) {
            switch (propensity.name) {
                case 'RG Churn':
                    text += ' (No data available as donor is not an active recurring giver)';
                    break;
                case 'RG Upgrade':
                    text += ' (No data available as donor is not an active recurring giver)';
                    break;
                case 'RG Reactivation':
                    text += ' (No data available as donor is not a lapsed recurring giver)';
                    break;
                default:
                    text +=
                        ' (No data available as donor has never given a financial contribution)';
                    break;
            }
        }

        return text;
    }

    return (
        <div className={classes.container}>
            {propensities?.map((item) => (
                <CardComponent
                    key={`propensity-${item.y_var}`}
                    text={`Likely to ${item.action}`}
                    title={item.name}
                    rank={item.rank}
                    value={parseScore(item)}
                    tooltip={getTooltip(item)}
                    publishScores={item.publish_scores}
                    {...item}
                />
            ))}
        </div>
    );
}

export default DataroTile;
