import React from 'react';
import { Column, Row } from 'simple-flexbox';
import { createUseStyles, useTheme } from 'react-jss';
import TooltipComponent from './tooltip';

const useStyles = createUseStyles({
    container: {
        border: '1px solid #E8E8E8',
        boxSizing: 'border-box',
        borderRadius: 10,
        padding: '18px 0px 10px 14px',
        position: 'relative',
        width: '100%'
    },
    text: {
        fontWeight: 390,
        fontSize: 15,
        lineHeight: '31px',
        letterSpacing: '-0.35px',
        color: '#5D5D5D',
        mixBlendMode: 'normal',
        opacity: '0.9'
    },
    titleContainer: {
        backgroundColor: 'white',
        position: 'absolute',
        top: -18,
        padding: '0px 2px'
    },
    title: {
        color: '#5D5D5D',
        fontSize: 15,
        fontWeight: 450,
        lineHeight: '31px',
        letterSpacing: '-0.35px'
    },
    tooltipContainer: {
        position: 'absolute',
        top: 0,
        right: -24,
        padding: '0px 2px'
    },
    value: {
        fontWeight: 450,
        fontSize: 40,
        lineHeight: '57px',
        letterSpacing: '-0.875px',
        color: '#A590E2'
    },
    rankContainer: {
        width: '100%',
        marginLeft: 12,
        maxWidth: 96
    },
    rankContainerBig: {
        width: '100%',
        maxWidth: 140,
        alignSelf: 'flex-end',
        justifyContent: 'flex-start',
        height: '100%'
    },
    rankTitle: {
        fontWeight: 450,
        fontSize: 14,
        lineHeight: '15px',
        letterSpacing: '-0.35px',
        color: '#5D5D5D'
    },
    rankTitleBig: {
        fontWeight: 450,
        fontSize: 24,
        lineHeight: '28px',
        letterSpacing: '-0.35px',
        color: '#5D5D5D'
    },
    rankSeparator: {
        width: '100%',
        height: 1,
        backgroundColor: '#E8E8E8',
        margin: '2px 0px 1px 0px'
    },
    rankValue: {
        fontWeight: 450,
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: '-0.875px',
        color: '#A590E2'
    },
    rankValueBig: {
        fontWeight: 450,
        fontSize: 24,
        lineHeight: '28px',
        letterSpacing: '-0.875px',
        color: '#A590E2'
    },
    centeredValueBig: {
        fontWeight: 450,
        fontSize: 52,
        lineHeight: '56px',
        letterSpacing: '-0.875px',
        color: '#A590E2'
    }
});
function CardComponent(item) {
    const theme = useTheme();
    const classes = useStyles({ theme });
    const { title, tooltip, y_var } = item;
    const justifyContent = ['y_channel_rec', 'y_meta_ask_amount'].includes(y_var)
        ? 'center'
        : 'flex-end';

    return (
        <Column className={classes.container} justifyContent={justifyContent}>
            <div className={classes.titleContainer}>
                <span className={classes.title}>{title}</span>
                <div className={classes.tooltipContainer}>
                    <TooltipComponent type='info' message={tooltip} />
                </div>
            </div>
            <CardContent item={item} />
        </Column>
    );
}

function CardContent({ item }) {
    const theme = useTheme();
    const classes = useStyles({ theme });
    const { rank, text, value, publishScores, y_var } = item;

    if (y_var === 'y_channel_rec') {
        return (
            <Row horizontal='center' style={{ paddingRight: 14 }}>
                <h2 className={classes.value}>{value}</h2>
            </Row>
        );
    }

    if (y_var === 'y_meta_ask_amount') {
        return (
            <Row horizontal='center' style={{ paddingRight: 14 }}>
                <h2 className={classes.value}>{value}</h2>
            </Row>
        );
    }
    return (
        <>
            {publishScores ? (
                <Row horizontal='space-between'>
                    <h2 className={classes.value}>{value}</h2>
                    {rank && (
                        <Column vertical='end' className={classes.rankContainer}>
                            <Column>
                                <span className={classes.rankTitle}>Rank:</span>
                                <div className={classes.rankSeparator} />
                                <span className={classes.rankValue}>{rank}</span>
                            </Column>
                        </Column>
                    )}
                </Row>
            ) : (
                <Column vertical='center' className={classes.rankContainerBig}>
                    <Column style={{ marginBottom: 0 }}>
                        <span className={classes.rankTitleBig}>Rank:</span>
                        <div className={classes.rankSeparator} />
                        <span className={classes.rankValueBig}>{rank}</span>
                    </Column>
                </Column>
            )}
            {publishScores && <span className={classes.text}>{text}</span>}
        </>
    );
}

CardComponent.defaultProps = {
    text: 'Likely to Lorum Ipsum',
    title: 'DM Appeal',
    value: '66%'
};

export default CardComponent;
