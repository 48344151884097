import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Row } from 'simple-flexbox';
import Image from 'assets/img-not-ready';

const useStyles = createUseStyles({
    container: {
        minHeight: 370,
        position: 'relative'
    },
    imageContainer: {
        position: 'absolute',
        bottom: -36,
        right: 14,
        '@media (max-width: 654px)': {
            position: 'relative',
            bottom: 0,
            right: 0,
            top: 0,
            left: 0,
            marginBottom: -7
        }
    },
    firstColumn: {
        flexGrow: 1,
        padding: 30,
        width: 400,
        paddingRight: 0,
        margin: 'auto 0px'
    },
    link: {
        color: '#FFB282',
        marginLeft: 4,
        transition: 'all 0.4s ease-in-out',
        textDecoration: 'none',
        '&:hover': {
            color: '#8564e7'
        }
    },
    secondColumn: {
        flexGrow: 1,
        width: 230,
        position: 'relative',
        '@media (max-width: 654px)': {
            width: 402
        }
    },
    subLine: {
        width: 93,
        height: 3,
        backgroundColor: '#FFB282',
        marginBottom: 30
    },
    title: {
        fontWeight: 450,
        fontSize: 40,
        lineHeight: '112.5%',
        letterSpacing: '-0.473214px',
        color: '#696969',
        marginBottom: 19
    }
});

function NotReady({ connectUrl }) {
    const theme = useTheme();
    const classes = useStyles({ theme });

    return (
        <Row horizontal='space-between' vertical='end' wrap className={classes.container}>
            <div vertical='center' className={classes.firstColumn}>
                <h1 className={classes.title}>
                    Thanks for connecting to Dataro. Your scores will be ready shortly
                </h1>
                <div className={classes.subLine} />
                <div>
                    if a new tab has not been opened,
                    <br /> please click on this{' '}
                    <a
                        className={classes.link}
                        href={connectUrl}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        link
                    </a>
                </div>
            </div>
            <Row horizontal='flex-end' className={classes.secondColumn}>
                <div className={classes.imageContainer}>
                    <Image />
                </div>
            </Row>
        </Row>
    );
}

export default NotReady;
