import React, { useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Column, Row } from 'simple-flexbox';
import LoadingComponent from 'components/loading';
import FormComponent from 'components/form/FormComponent';
import FormInputComponent from 'components/form/input';
import CheckboxComponent from 'components/form/checkbox';
import ButtonComponent from 'components/form/ButtonComponent';
import ImgNotConnected from 'assets/img-not-connected';
import TCComponent from './TCComponent';

const useStyles = createUseStyles({
    checkboxLabel: {
        fontWeight: 'normal',
        fontSize: 16,
        lineHeight: '136.4%',
        letterSpacing: '-0.4px',
        color: '#424147'
    },
    checkboxLink: {
        fontWeight: 'normal',
        fontSize: 16,
        lineHeight: '136.4%',
        letterSpacing: '-0.4px',
        color: '#424147',
        textDecoration: 'underline'
    },
    container: {
        minHeight: 370,
        position: 'relative'
    },
    firstColumn: {
        padding: 30,
        paddingRight: 0,
        margin: 'auto 0px'
    },
    title: {
        fontWeight: 450,
        fontSize: 40,
        lineHeight: '40px',
        letterSpacing: '-0.473214px',
        color: '#696969',
        marginBottom: 19
    },
    inputsContainer: {
        display: 'grid',
        'grid-template-columns': 'repeat(auto-fill, minmax(280px, 1fr))',
        'row-gap': '5px',
        'column-gap': '15px'
    }
});

function NotIntegrated({ userData, connectUser }) {
    const theme = useTheme();
    const classes = useStyles({ theme });
    const [showForm, setShowForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    function onClick() {
        setShowForm((prev) => !prev);
    }

    async function onSubmit({ email, given_name, family_name, phone_number }) {
        setIsLoading(true);
        const client_name = userData.environment_id;
        await connectUser({ email, given_name, family_name, phone_number, client_name });
        setIsLoading(false);
    }

    return !showForm ? (
        <Row horizontal='space-between' vertical='end' wrap className={classes.container}>
            <div vertical='center' className={classes.firstColumn}>
                <h1 className={classes.title}>You are not connected</h1>
                <ButtonComponent width={245} onClick={onClick}>
                    Connect
                </ButtonComponent>
            </div>
            <Row flexGrow={1} horizontal='flex-end'>
                <ImgNotConnected />
            </Row>
        </Row>
    ) : (
        <div>
            <FormComponent onSubmit={onSubmit}>
                <Column>
                    <div className={classes.inputsContainer}>
                        <FormInputComponent
                            name='given_name'
                            required='Please provide a valid first name'
                            label='First name'
                            autoComplete='off'
                            placeholder='First name'
                            defaultValue={userData.given_name}
                            showErrorMessageBelow
                        />
                        <FormInputComponent
                            name='family_name'
                            required='Please provide a valid last name'
                            label='Last name'
                            autoComplete='off'
                            placeholder='Last name'
                            defaultValue={userData.family_name}
                            showErrorMessageBelow
                        />
                        <FormInputComponent
                            type='email'
                            placeholder='Email'
                            label='Email'
                            name='email'
                            required='Please provide a valid email address'
                            pattern={{
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Please provide a valid email address'
                            }}
                            autoComplete='off'
                            defaultValue={userData.email}
                            showErrorMessageBelow
                        />
                        <FormInputComponent
                            placeholder='Phone Number'
                            name='phone_number'
                            label='Phone Number'
                            type='text'
                            minLength={{
                                value: 8,
                                message: 'Please provide a valid phone number'
                            }}
                            required='Please provide a valid phone number'
                            autoComplete='off'
                            showErrorMessageBelow
                        />
                    </div>

                    <div style={{ marginTop: 25, marginBottom: 25 }}>
                        <TCComponent />
                    </div>

                    <div>
                        <div>
                            <CheckboxComponent
                                name='agree'
                                required='You must agree with the terms & conditions and privacy policy before continue'
                                noSelectableLabel
                                label={
                                    <span className={classes.checkboxLabel}>
                                        I agree to the{' '}
                                        <a
                                            href='https://dataro.io/privacy-policy-2/'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className={classes.checkboxLink}
                                        >
                                            Dataro Privacy Policy
                                        </a>{' '}
                                        and{' '}
                                        <a
                                            href='http://dataro.io/terms-conditions/'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className={classes.checkboxLink}
                                        >
                                            Terms & Conditions
                                        </a>
                                    </span>
                                }
                            />
                        </div>
                    </div>
                </Column>
                <Row horizontal='space-between' style={{ padding: 4, marginTop: 10 }} wrap>
                    <ButtonComponent secondary width={150} onClick={onClick}>
                        Cancel
                    </ButtonComponent>
                    <ButtonComponent width={150} type='submit'>
                        Connect
                    </ButtonComponent>
                </Row>
            </FormComponent>
            <LoadingComponent loading={isLoading} />
        </div>
    );
}

export default NotIntegrated;
