import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'react-jss';
import Theme from './resources/theme';
import Routes from './routes';
import './index.scss';

ReactDOM.render(
    <Router>
        <ThemeProvider theme={Theme}>
            <Routes />
        </ThemeProvider>
    </Router>,
    document.getElementById('root')
);
