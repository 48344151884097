import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
    container: {
        background: ({ secondary }) => (secondary ? '#ffffff' : theme.color.sidebarBackground),
        border: ({ secondary }) => (secondary ? '1px solid #c0c0c0' : 'none'),
        borderRadius: 10,
        padding: 12,
        cursor: 'pointer',
        outline: 'none',
        height: ({ height }) => height,
        width: ({ width }) => width,
        transition: 'background-color 0.2s',
        '&:hover': {
            background: ({ secondary }) => (!secondary ? '#ffffff' : theme.color.sidebarBackground),
            border: ({ secondary }) => (!secondary ? '1px solid #c0c0c0' : 'none'),
            color: ({ labelColor }) => labelColor || '#424147'
        },
        ...theme.typography.title18,
        color: ({ labelColor }) => labelColor || ''
    },
    disabled: {
        background: '#ffffff',
        border: '1px solid #c0c0c0',
        borderRadius: 10,
        padding: 12,
        cursor: 'not-allowed',
        outline: 'none',
        height: ({ height }) => height,
        width: ({ width }) => width
    }
}));

function ButtonComponent({
    className = '',
    children,
    height,
    width,
    secondary,
    labelColor,
    disabled,
    ...props
}) {
    const theme = useTheme();
    const classes = useStyles({ theme, height, labelColor, secondary, width });

    const classNameButton = disabled ? classes.disabled : [classes.container, className].join(' ');
    return (
        <button className={classNameButton} disabled={disabled} {...props}>
            {children}
        </button>
    );
}

ButtonComponent.defaultProps = {
    height: 50
};

export default ButtonComponent;
