const CURRENCIES = {
    AUD: {
        code: 'AUD',
        symbol: '$',
        fullName: 'Australian dollar'
    },
    USD: {
        code: 'USD',
        symbol: '$',
        fullName: 'United States dollar'
    },
    EUR: {
        code: 'EUR',
        symbol: '€',
        fullName: 'Euro'
    },
    GBP: {
        code: 'GBP',
        symbol: '£',
        fullName: 'British pound'
    },
    NZD: {
        code: 'NZD',
        symbol: '$',
        fullName: 'New Zealand dollar'
    },
    CAD: {
        code: 'CAD',
        symbol: '$',
        fullName: 'Canadian dollar'
    },
    TWD: {
        code: 'TWD',
        symbol: '$',
        fullName: 'New Taiwan dollar'
    },
    HKD: {
        code: 'HKD',
        symbol: '$',
        fullName: 'Hong Kong dollar'
    }
};

export default CURRENCIES;
