import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Column } from 'simple-flexbox';
import ButtonComponent from 'components/form/ButtonComponent';

const useStyles = createUseStyles({
    button: {
        marginTop: 10,
        marginBottom: 20
    }
});

function ErrorMessage({ error, setError }) {
    const theme = useTheme();
    const classes = useStyles({ theme });

    function onClick() {
        setError('');
    }

    return (
        <Column>
            <div>{error}</div>
            <ButtonComponent width={160} className={classes.button} onClick={onClick}>
                Try again
            </ButtonComponent>
        </Column>
    );
}

export default ErrorMessage;
