import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles({
    container: {
        width: '100%'
    },
    link: {
        fontWeight: 'normal',
        fontSize: 16,
        lineHeight: '136.4%',
        letterSpacing: '-0.4px',
        color: '#424147',
        textDecoration: 'underline'
    }
});

function TCComponent() {
    const theme = useTheme();
    const classes = useStyles({ theme });
    return (
        <>
            <div className={classes.container}>
                Connecting with Dataro allows us to:
                <br />
                <ul>
                    <li>
                        Access Constituent, Fundraising, Gift & Communications Preferences data{' '}
                    </li>
                    <li>
                        Use this data in accordance with our Terms & Conditions, including to build
                        propensity models, carry out benchmarking, and deliver our products &
                        services to you.
                    </li>
                </ul>
                You must be authorised by your organisation to agree to{' '}
                <a
                    href='https://dataro.io/terms-conditions/'
                    rel='noopener noreferrer'
                    target='_blank'
                    className={classes.link}
                >
                    Dataro’s Terms & Conditions
                </a>
                .
                <br />
            </div>
        </>
    );
}

export default TCComponent;
