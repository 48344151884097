import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 402}
        height={props.height || 253}
        viewBox='0 0 402 253'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            d='M276.234 176.415C274.924 176.384 273.584 176.384 272.275 176.384C271.246 176.384 270.186 176.384 269.157 176.384C267.973 176.384 266.788 176.415 265.603 176.446C265.51 176.446 265.416 176.446 265.323 176.446C265.323 176.571 265.292 176.695 265.292 176.82C265.105 178.784 264.98 180.779 264.855 182.774C264.73 185.237 264.637 187.669 264.575 190.131C264.543 192.47 264.543 194.808 264.606 197.146C264.606 197.582 264.481 198.891 264.73 199.172C264.949 199.421 284.931 199.702 285.43 199.64C286.022 199.577 286.085 198.642 286.209 197.956C286.646 195.618 287.113 193.28 287.55 190.942C288.049 188.261 288.579 185.58 289.077 182.899C289.452 180.966 289.826 179.033 290.169 177.101C285.555 176.727 280.879 176.508 276.234 176.415Z'
            fill='#C25A3E'
        />
        <path
            d='M276.234 176.415C274.925 176.384 273.584 176.384 272.275 176.384C269.968 176.571 267.63 176.727 265.292 176.851C265.105 178.815 264.98 180.81 264.855 182.806C272.93 182.806 281.004 183.117 289.078 182.93C289.452 180.997 289.826 179.065 290.169 177.132C285.555 176.727 280.879 176.508 276.234 176.415Z'
            fill='#401D1D'
        />
        <path
            d='M381.666 155.216C381.666 155.31 381.666 155.435 381.666 155.528C381.604 156.308 381.417 157.118 381.042 157.866C379.546 160.921 376.242 162.761 373.342 164.226C366.858 167.53 359.688 169.151 352.612 170.523C344.818 172.019 336.993 173.485 329.137 174.638C327.953 174.794 326.768 174.981 325.552 175.137L305.164 170.43L288.954 166.689C288.642 165.847 288.143 165.068 287.489 164.444C287.333 164.288 287.146 164.132 286.959 163.976C287.52 163.758 288.05 163.54 288.58 163.353C289.079 163.166 289.608 162.948 290.107 162.761C303.076 157.835 316.356 153.564 329.886 150.384C331.6 149.979 333.315 149.605 335.061 149.231C342.667 147.61 350.429 146.145 358.192 145.615C362.089 145.365 366.048 145.397 369.913 146.02C373.311 146.55 377.021 147.454 379.484 150.01C380.855 151.444 381.697 153.315 381.666 155.216Z'
            fill='#8A9177'
        />
        <path
            d='M394.447 114.69C386.528 119.491 378.111 123.232 369.788 127.129C361.153 131.15 352.736 135.67 344.599 140.658C339.58 143.744 334.655 146.987 329.854 150.385C326.83 152.536 323.837 154.749 320.907 157.056C315.451 161.296 310.183 165.785 305.133 170.461C303.699 171.77 302.296 173.079 300.924 174.42C300.238 174.139 299.49 173.983 298.711 173.983C296.716 173.983 294.783 175.075 293.661 176.727L265.323 176.851C265.51 175.885 265.884 174.545 266.103 173.578C268.098 164.662 271.246 155.996 275.299 147.797C279.383 139.567 284.371 131.774 290.044 124.541C295.812 117.184 302.265 110.419 309.248 104.216C316.387 97.8871 324.087 92.0576 332.285 87.1944C341.045 82.0195 350.959 77.9045 361.308 78.1227C363.491 78.185 365.704 78.3097 367.73 79.0579C369.289 79.619 370.91 80.5543 371.752 82.0506C372.749 83.7964 372.188 85.698 371.128 87.2879C369.757 89.3142 367.98 91.1535 366.234 92.8681C362.649 96.3284 358.565 99.29 354.45 102.096C350.21 104.995 345.908 107.801 341.7 110.824C333.283 116.872 325.178 123.356 317.478 130.277C309.809 137.135 302.514 144.43 295.656 152.099C289.078 159.425 282.874 167.094 277.076 175.043C278.323 173.734 279.57 172.425 280.848 171.115C283.03 168.871 285.212 166.626 287.426 164.413C287.8 164.039 288.143 163.696 288.517 163.322C292.788 159.051 297.152 154.874 301.579 150.79C308.655 144.243 315.95 137.946 323.494 131.961C330.976 126.037 338.676 120.395 346.657 115.189C350.709 112.539 354.824 110.014 359.002 107.582C363.304 105.088 367.668 102.688 372.219 100.755C380.418 97.2948 389.303 94.5203 397.938 97.7624C398.343 97.9183 399.746 98.9159 400.339 99.6952C400.401 99.7888 400.463 99.8511 400.526 99.9446C402.583 102.875 403.892 108.954 394.447 114.69Z'
            fill='#8A9177'
        />
        <path
            d='M400.588 99.9757C389.927 104.059 379.39 108.455 369.227 113.692C358.69 119.085 348.589 125.383 339.455 132.958C339.3 133.083 339.081 132.864 339.237 132.74C344.35 128.5 349.774 124.666 355.417 121.143C365.486 114.877 376.21 109.671 387.121 105.088C391.516 103.218 395.974 101.472 400.432 99.7574C400.463 99.8198 400.526 99.8821 400.588 99.9757Z'
            fill='#697052'
        />
        <path
            d='M381.665 155.216C381.665 155.31 381.665 155.435 381.665 155.528C381.166 155.528 380.699 155.528 380.2 155.528C371.471 155.466 362.898 156.183 354.325 157.804C345.846 159.394 337.46 161.327 329.012 163.104C328.825 163.135 328.731 162.854 328.918 162.792C333.782 161.763 338.614 160.734 343.477 159.674C351.832 157.897 360.186 156.12 368.697 155.528C373.061 155.216 377.363 155.154 381.665 155.216Z'
            fill='#697052'
        />
        <path
            d='M282.874 254.039H282.562C282.656 249.113 282.905 239.855 283.186 234.96C284.277 215.944 285.836 196.46 280.63 177.849C280.567 177.662 280.879 177.568 280.941 177.755C282.344 182.806 283.279 187.981 283.841 193.218C285.867 212.016 283.248 235.21 282.874 254.039Z'
            fill='#401D1D'
        />
        <path
            d='M222.459 202.29C222.054 207.901 221.43 213.512 220.932 219.124C219.934 229.848 218.594 243.845 217.565 254.912H217.253C217.845 248.646 218.687 239.854 219.217 234.118C219.747 228.538 220.246 222.958 220.776 217.378C221.275 211.891 221.898 206.405 222.241 200.887C222.584 195.462 222.335 190.1 220.932 184.832C219.56 179.751 217.409 174.919 215.258 170.118C215.164 169.931 215.445 169.775 215.538 169.962C217.908 175.293 220.339 180.686 221.649 186.422C222.802 191.69 222.833 196.99 222.459 202.29Z'
            fill='#401D1D'
        />
        <path
            d='M208.867 202.227C208.805 202.57 208.711 202.944 208.618 203.318C208.368 204.503 208.056 205.843 207.745 207.34C204.877 220.464 199.826 244.375 197.426 255.161L193.031 255.192C195.431 244.468 200.388 220.932 203.287 207.683C203.63 206.093 203.941 204.628 204.222 203.381C204.315 202.913 204.409 202.477 204.503 202.071C204.534 201.915 204.565 201.791 204.596 201.635C205.812 201.915 207.807 202.258 208.867 202.227Z'
            fill='#B8A59C'
        />
        <path
            d='M326.705 201.479C326.487 202.414 326.268 203.474 325.988 204.628C325.738 205.812 325.427 207.122 325.115 208.525C322.216 221.711 317.695 242.816 315.201 253.945L311.149 253.977C313.611 242.785 318.537 219.778 320.969 208.836C321.187 207.901 321.374 207.028 321.53 206.28C321.655 205.75 321.779 205.22 321.904 204.69C322.153 203.599 322.403 202.477 322.652 201.386C324.959 201.448 324.429 201.479 326.705 201.479Z'
            fill='#B8A59C'
        />
        <path
            d='M204.253 203.381C203.973 204.628 203.661 206.093 203.318 207.683C204.814 207.558 206.279 207.465 207.745 207.34C208.088 205.844 208.368 204.503 208.649 203.318C207.152 203.35 205.687 203.35 204.253 203.381Z'
            fill='#99867E'
        />
        <path
            d='M321.935 204.69C321.811 205.22 321.686 205.75 321.561 206.28C321.374 207.028 321.187 207.901 321 208.836C321.904 208.774 322.777 208.712 323.681 208.618C324.18 208.587 324.679 208.556 325.146 208.493C325.458 207.09 325.739 205.781 326.019 204.597C324.648 204.659 323.307 204.659 321.935 204.69Z'
            fill='#99867E'
        />
        <path
            d='M118.618 194.247L42.584 199.172C49.0682 192.937 43.4569 203.163 43.4569 203.194L180.28 202.258L342.916 201.074L118.618 194.247Z'
            fill='#8A3C27'
        />
        <path
            d='M84.9797 199.328C84.6992 201.541 84.5745 201.23 84.1069 203.412L341.73 205.158C342.136 202.944 342.323 203.256 342.915 201.074L84.9797 199.328Z'
            fill='#401D1D'
        />
        <path
            d='M212.67 194.995C211.111 194.964 206.591 194.932 203.099 194.901C201.416 194.901 199.982 194.87 199.234 194.87C197.706 188.105 196.179 181.372 194.651 174.607C194.215 172.736 193.809 170.897 193.404 169.027C192.905 166.782 192.375 164.537 191.877 162.262C191.783 161.888 191.721 161.545 191.627 161.171C192.687 161.202 193.747 161.171 194.776 161.077C195.929 160.984 203.723 160.859 204.908 160.609C204.939 160.765 204.97 160.89 205.001 161.046C205.624 163.789 206.248 166.501 206.84 169.245C207.183 170.772 207.526 172.3 207.869 173.796C209.49 180.904 211.08 187.949 212.67 194.995Z'
            fill='#F28F68'
        />
        <path
            d='M206.84 169.182C202.631 168.964 198.454 168.902 194.245 168.933C193.965 168.933 193.684 168.933 193.373 168.933C193.778 170.804 194.214 172.643 194.62 174.513C194.744 174.513 194.9 174.482 195.025 174.482C197.612 174.295 200.2 174.139 202.787 173.983C204.471 173.89 206.154 173.796 207.869 173.734C207.526 172.206 207.183 170.679 206.84 169.182Z'
            fill='#401D1D'
        />
        <path
            d='M279.601 86.5396C279.601 86.602 279.57 86.6643 279.57 86.6955L278.728 90.9664L275.206 108.985L263.734 167.904C263.422 169.463 262.05 170.617 260.429 170.617H139.847C138.07 170.617 136.73 168.995 137.073 167.25L153.096 85.0121C153.346 83.6716 154.53 82.7052 155.871 82.7052H276.234C276.577 82.7052 276.889 82.7676 277.201 82.8299C278.791 83.3287 279.851 84.8874 279.601 86.5396Z'
            fill='#F28F68'
        />
        <path d='M274.801 84.7314L260.772 156.962H140.876L154.905 84.7314H274.801Z' fill='white' />
        <path
            d='M213.075 197.582L186.889 197.208L186.234 193.717L212.545 194.091L213.075 197.582Z'
            fill='#B36242'
        />
        <path
            d='M212.514 194.309C211.61 194.278 210.738 194.247 209.833 194.247L204.503 170.648H207.153C207.371 171.739 211.797 190.381 212.514 194.309Z'
            fill='#B36242'
        />
        <path
            d='M194.963 198.112H118.368L119.709 194.247H196.304L194.963 198.112Z'
            fill='#401D1D'
        />
        <path
            d='M116.81 238.576C116.436 237.797 115.282 237.61 114.534 237.391C113.35 237.017 112.134 236.706 110.918 236.425C109.11 236.02 107.271 235.739 105.4 235.552C104.839 235.49 104.309 235.459 103.748 235.427C100.973 235.272 98.1677 235.365 95.4244 235.739C93.6163 235.989 91.2159 236.737 91.3094 239.013C91.3094 239.168 91.5277 239.2 91.59 239.106C91.4653 240.104 91.3406 241.132 91.2159 242.13C91.1224 242.816 91.0289 243.502 90.9353 244.187C90.3742 248.24 89.7507 252.262 89.0026 256.283L114.409 256.034C114.565 255.067 114.721 254.101 114.877 253.103C115.251 250.734 115.625 248.365 115.968 245.996C115.999 245.746 116.031 245.497 116.093 245.247C116.28 243.938 116.467 242.629 116.654 241.319C116.779 240.478 117.153 239.355 116.81 238.576Z'
            fill='#7D443B'
        />
        <path
            d='M34.9462 224.984C34.011 229.13 33.0758 233.276 32.1405 237.423C31.7664 239.137 31.3612 240.852 30.9871 242.535C30.8624 243.065 30.7378 243.564 30.6442 244.094C29.6778 248.365 28.7114 252.667 27.745 256.938L23.3806 257C24.347 252.698 25.3134 248.427 26.2798 244.125C27.7139 237.703 29.179 231.281 30.6442 224.859C30.6754 224.704 30.7065 224.579 30.7377 224.423C31.8911 224.672 33.8863 225.015 34.9462 224.984Z'
            fill='#B8A59C'
        />
        <path
            d='M90.1241 224.142C89.3136 227.696 88.5031 231.25 87.7237 234.773C87.2249 236.924 86.7574 239.044 86.2897 241.195C86.0715 242.192 85.8532 243.19 85.6039 244.187C84.6998 248.24 83.7646 252.293 82.8606 256.377L78.4962 256.408C79.4314 252.324 80.3355 248.271 81.2707 244.187C81.5512 242.909 81.863 241.631 82.1436 240.353C82.5488 238.514 82.9853 236.674 83.3905 234.835C84.2011 231.219 85.0116 227.634 85.8221 224.018C85.8533 223.862 85.8844 223.737 85.9156 223.581C87.069 223.831 89.0642 224.174 90.1241 224.142Z'
            fill='#B8A59C'
        />
        <path
            d='M154.966 241.475C154.841 245.809 153.937 251.701 153.22 255.628L147.734 255.691H147.703C146.393 255.66 145.115 255.691 143.837 255.722H143.806L121.267 255.971C121.984 253.166 123.543 248.801 122.981 246.526C122.607 246.526 122.264 246.526 122.015 246.526C120.706 246.526 118.586 246.307 115.998 245.996C111.821 245.497 106.397 244.686 101.16 243.813C97.6368 243.252 94.1765 242.66 91.2461 242.099C89.3445 241.756 87.6611 241.444 86.2894 241.164C85.4166 240.977 84.6995 240.852 84.1384 240.727C83.4837 240.571 82.7979 240.447 82.1121 240.322C78.7141 239.73 75.1914 239.511 71.7622 238.95C70.827 238.794 69.8918 238.639 68.9566 238.389C66.1509 237.734 63.5011 236.737 61.1007 234.96C60.7889 234.711 60.446 234.461 60.1342 234.181C57.1104 231.593 55.5205 228.164 55.1776 224.205C54.9594 221.836 55.0529 219.466 55.1464 217.097C55.2087 214.634 55.3334 212.14 55.4581 209.678H55.427C55.2399 209.74 55.1464 209.428 55.3335 209.366C58.7626 208.368 62.1917 207.402 65.6521 206.467C66.4626 206.248 67.2732 206.03 68.0525 205.812C73.9133 204.253 79.774 202.819 85.666 201.479C87.9417 200.949 90.2174 200.45 92.4931 199.982C93.7089 199.733 95.891 198.923 96.8263 200.201C97.2939 200.824 97.1692 201.728 97.1692 202.445C97.138 203.536 97.1069 204.627 97.1069 205.718C97.0757 206.81 96.7639 208.431 97.2939 209.459C97.7615 210.395 98.9773 210.706 99.8814 211.018C108.423 213.949 142.559 222.085 149.074 227.104C152.067 229.442 155.309 231.531 154.966 241.475Z'
            fill='#401D1D'
        />
        <path
            d='M212.359 191.441C211.112 190.786 203.724 191.659 203.63 191.69C200.481 192.407 198.517 195.182 197.863 198.33C197.832 198.424 197.894 198.548 198.019 198.548C205.594 198.424 209.771 198.891 216.474 198.673C216.536 198.673 216.599 198.642 216.63 198.58C217.378 195.369 214.884 192.75 212.359 191.441Z'
            fill='#401D1D'
        />
        <path
            d='M217.689 191.347C216.785 191.784 215.819 191.815 214.853 191.846C212.327 191.908 209.522 191.721 207.402 191.41C207.433 191.784 207.433 192.158 207.433 192.532C207.402 193.685 206.155 198.517 204.596 199.702C203.942 200.201 203.567 199.39 203.505 198.767C203.006 199.982 202.32 200.949 201.541 200.762C201.479 200.855 201.416 200.949 201.323 201.042C200.886 201.51 200.263 201.385 200.076 200.762C199.951 200.325 199.982 199.795 199.951 199.328C199.889 198.611 199.858 197.894 199.795 197.208C199.733 196.491 199.546 194.153 199.203 193.903C197.239 193.342 192.843 192.251 190.973 191.441C190.256 191.129 189.633 190.755 189.258 190.069C189.227 190.007 188.947 189.57 188.916 189.508C187.45 189.539 186.235 189.57 184.863 189.664C183.678 189.726 182.369 189.82 180.717 189.944C180.343 189.975 179.937 190.007 179.532 190.007C179.439 190.007 179.813 189.57 179.813 189.477C180.031 187.949 180.436 186.484 181.028 185.05C180.966 184.988 180.966 184.801 181.122 184.801H181.153C181.184 184.801 181.216 184.801 181.216 184.801C181.403 184.801 181.621 184.801 181.808 184.801C182.65 184.769 183.491 184.738 184.333 184.707C185.736 184.645 187.139 184.551 188.51 184.458C189.695 184.364 190.848 184.208 192.033 184.208C193.124 184.208 194.122 184.52 195.119 184.894C199.608 186.577 210.457 189.258 211.33 189.352C213.263 189.57 214.291 189.508 215.85 189.383C216.287 189.352 216.754 189.29 217.284 189.227C217.502 189.196 217.752 189.165 218.001 189.196C218.375 189.227 218.718 189.352 218.812 189.82C219.092 190.568 218.251 191.067 217.689 191.347Z'
            fill='#BF6541'
        />
        <path
            d='M113.131 134.672C109.608 132.615 105.836 130.994 101.877 129.872C105.711 123.637 109.608 117.433 113.598 111.292C117.277 112.944 121.049 114.596 124.26 117.028C120.488 122.889 116.778 128.749 113.131 134.672Z'
            fill='#BF6541'
        />
        <path
            d='M138.694 107.863C138.289 106.647 135.14 101.971 134.517 100.661C134.423 100.474 134.174 100.599 134.236 100.786C134.205 100.786 134.205 100.817 134.174 100.817C134.111 100.724 133.893 100.724 133.893 100.88C133.644 104.558 134.299 108.174 134.984 111.791C135.016 111.884 135.109 111.946 135.203 111.915C136.917 111.354 139.505 110.138 138.694 107.863Z'
            fill='#BF6541'
        />
        <path
            d='M137.727 109.64C137.134 109.64 136.573 109.141 136.105 108.237C135.669 107.364 135.388 106.211 135.295 104.964C135.201 103.717 135.295 102.532 135.607 101.597C135.918 100.599 136.448 100.007 137.072 99.9446C138.288 99.851 139.379 101.846 139.597 104.59C139.691 105.837 139.597 107.021 139.285 107.956C138.974 108.954 138.444 109.546 137.82 109.609C137.789 109.64 137.758 109.64 137.727 109.64ZM137.134 100.599H137.103C136.76 100.63 136.417 101.067 136.199 101.815C135.918 102.657 135.825 103.748 135.918 104.932C136.012 106.086 136.293 107.177 136.667 107.956C137.01 108.642 137.415 109.047 137.758 109.016C138.101 108.985 138.444 108.549 138.662 107.801C138.942 106.959 139.036 105.868 138.942 104.683C138.755 102.251 137.851 100.599 137.134 100.599Z'
            fill='#F7E9E1'
        />
        <path
            d='M135.669 115.906C135.701 115.313 135.732 114.378 135.763 113.661C135.763 113.474 135.763 113.318 135.763 113.193C135.763 110.855 135.607 108.486 135.42 106.148C135.264 104.184 135.077 94.2707 134.921 93.2108C134.921 93.1173 134.859 93.0861 134.797 93.0861C134.765 93.0237 134.734 92.9926 134.672 92.9926C128.842 93.1796 122.078 95.4865 117.09 98.5416C115.843 99.2897 114.627 100.131 113.505 101.067C112.476 101.908 111.541 102.844 111.135 104.153C110.699 105.618 110.668 107.333 110.699 108.86C110.73 110.201 110.917 111.572 111.416 112.819C111.977 114.253 112.912 115.407 114.222 116.28C115.406 117.09 117.963 119.459 117.963 119.397C119.864 121.33 121.922 123.294 124.447 124.354C126.816 125.351 130.089 125.632 132.334 124.198C134.765 122.608 135.327 120.021 135.576 117.246C135.638 116.778 135.669 116.342 135.669 115.906Z'
            fill='#BF6541'
        />
        <path
            d='M134.392 104.184C134.392 105.493 133.831 106.553 133.176 106.553C132.521 106.553 131.991 105.493 131.991 104.184C131.991 102.875 132.553 101.815 133.207 101.815C133.893 101.815 134.423 102.906 134.392 104.184Z'
            fill='#401D1D'
        />
        <path
            d='M135.108 96.9517C133.705 97.014 131.585 97.3881 130.494 98.0739C129.59 98.6351 129.652 99.6015 130.806 100.007C131.523 100.287 132.365 100.225 133.113 100.225C133.643 100.225 134.204 100.256 134.734 100.256C134.765 100.256 135.014 100.287 135.233 100.225C135.17 98.9468 135.264 98.0739 135.108 96.9517Z'
            fill='#733220'
        />
        <path
            d='M184.395 184.676C183.553 184.707 182.711 184.738 181.869 184.769C181.495 186.172 181.153 187.575 180.903 188.978C180.872 189.196 180.778 189.539 180.716 189.913C182.368 189.789 183.678 189.695 184.862 189.633C184.706 187.98 184.55 186.328 184.395 184.676Z'
            fill='#9C4725'
        />
        <path
            d='M135.764 113.661C134.673 114.378 133.239 114.222 131.898 114.066C131.026 113.942 130.963 114.659 131.618 115.095C132.584 115.718 134.237 116.373 135.39 116.03C135.483 115.999 135.577 115.968 135.67 115.906C135.702 115.313 135.764 114.378 135.764 113.661Z'
            fill='white'
        />
        <path
            d='M137.789 87.724C137.166 87.4746 136.262 87.4746 135.981 86.7265C135.763 86.1653 135.763 85.4795 135.638 84.9183C135.451 83.9831 135.358 82.9232 134.765 82.1438C133.425 80.3357 130.37 80.9592 128.593 81.8321C128.437 80.211 126.878 79.2446 125.288 79.2135C123.324 79.1823 121.329 80.211 119.895 81.458C119.085 82.175 118.524 83.0479 117.807 83.7961C116.84 84.7936 115.624 84.9183 114.315 84.8248C113.318 84.7625 112.32 84.5754 111.322 84.7625C110.387 84.9183 109.514 85.386 108.797 85.9783C107.114 87.4123 106.179 89.5633 106.148 91.7143C104.402 92.3378 102.438 93.3042 102.251 95.3305C102.188 95.8293 102.344 96.2658 102.469 96.7334C102.718 97.6062 102.594 98.2921 102.064 99.0091C101.378 99.9443 100.349 100.568 99.8815 101.659C99.258 103.186 99.6944 105.088 100.973 106.117C102.656 107.457 104.901 107.208 106.865 106.709C105.742 107.707 104.713 109.639 106.335 110.388C106.802 110.606 107.363 110.637 107.831 110.793C108.579 111.042 109.14 111.603 109.514 112.289C110.294 113.754 110.169 114.191 109.857 115.718C109.701 116.03 109.701 116.373 109.951 116.716C110.481 117.402 112.226 117.184 113.006 117.121C113.567 117.09 114.128 116.903 114.689 116.81C115.469 116.654 116.186 116.778 116.965 116.747C117.931 116.716 117.9 115.75 117.869 115.001C117.838 113.723 118.181 113.162 117.869 111.946C117.495 110.575 116.092 109.047 116.529 107.644C116.965 106.21 118.025 105.961 119.397 105.493C120.519 105.119 121.703 104.995 122.794 104.496C124.79 103.561 125.101 101.721 124.883 99.8508C126.379 100.131 128.001 99.8508 129.247 98.9156C130.307 98.105 131.024 96.9516 131.274 95.6734C132.365 95.6734 133.581 95.3617 134.422 95.1123C136.324 94.52 138.693 93.3665 139.66 91.5273C140.595 89.9062 139.379 88.3787 137.789 87.724Z'
            fill='#733220'
        />
        <path
            d='M139.224 103.311C135.795 103.654 132.334 103.997 128.905 104.34C125.102 104.714 121.299 105.244 117.495 105.119C117.09 105.119 116.997 105.712 117.402 105.743C121.08 105.867 124.728 105.4 128.375 105.026C132.023 104.652 135.67 104.309 139.286 103.935C139.255 103.716 139.224 103.498 139.224 103.311Z'
            fill='#F7E9E1'
        />
        <path
            d='M121.735 105.618C120.021 103.779 116.997 103.935 116.342 106.242C115.656 108.548 117.714 111.385 119.615 112.476C120.644 113.069 121.86 113.069 122.951 112.601C124.073 112.133 124.447 111.198 124.603 110.045C124.946 107.863 124.447 106.179 121.735 105.618Z'
            fill='#BF6541'
        />
        <path
            d='M183.179 183.647C180.529 183.772 176.726 184.239 174.232 183.211C173.11 182.743 169.494 180.311 168.153 180.467C165.41 180.779 148.825 179.314 147.609 175.604C147.609 175.573 147.609 175.542 147.609 175.511C146.144 172.05 142.684 163.945 141.25 160.485C139.909 157.274 138.6 154.032 137.104 150.883C134.423 145.334 130.744 140.44 125.85 136.668C124.291 135.452 122.608 134.361 120.862 133.425C120.488 132.397 120.145 131.368 119.771 130.37C119.428 129.404 119.179 127.814 118.337 127.097C117.402 126.287 105.961 122.826 102.781 123.169C100.349 123.45 99.8195 125.569 98.3854 127.159C93.3041 128.219 73.0097 131.68 70.2976 132.708C67.5231 133.768 65.0914 135.389 62.8469 137.291C60.3218 139.473 58.0461 141.936 55.6768 144.305C53.1829 146.83 50.6578 149.387 48.1639 151.912C45.9194 154.187 43.3943 156.37 41.6173 159.019C40.2145 161.108 38.6869 164.475 39.7157 166.875C43.6748 176.352 52.4659 175.199 56.4874 174.762C55.8016 176.196 53.3388 187.076 52.2477 190.505C51.2189 193.748 50.4396 199.265 51.6554 202.57C52.84 205.781 55.1781 208.493 55.8016 211.891C55.8328 211.984 55.9262 212.016 55.9886 211.984C67.9595 209.21 80.1174 207.215 92.3377 205.999C93.8652 205.843 95.3616 205.718 96.8891 205.594C98.0114 205.5 98.4166 205.033 98.8843 204.035C100.038 201.541 114.503 175.386 115.313 173.391C117.963 175.854 131.305 196.335 133.799 198.361C134.766 199.172 135.857 199.92 137.073 200.294C138.444 200.699 159.082 203.349 166.875 202.32C168.091 202.165 169.213 201.448 170.086 200.575C170.99 199.702 171.614 198.58 172.268 197.52C173.172 196.117 174.232 195.088 175.729 194.371C177.131 193.685 178.628 193.28 180.062 192.656C180.654 192.407 181.246 192.095 181.62 191.565C182.057 190.942 182.15 190.131 182.275 189.383C182.618 187.513 182.961 185.673 183.304 183.803C183.335 183.741 183.273 183.647 183.179 183.647Z'
            fill='#B8A59C'
        />
        <path
            d='M121.891 109.546C120.395 109.421 120.551 107.8 120.208 106.74C120.021 106.242 119.771 105.836 119.335 105.525C119.179 105.4 119.023 105.681 119.179 105.805C120.675 106.896 119.615 109.671 121.891 109.858C122.078 109.889 122.078 109.577 121.891 109.546Z'
            fill='#9C4725'
        />
        <path
            d='M202.195 191.908C201.728 191.254 200.855 190.942 200.169 190.568C199.982 190.474 199.826 190.755 200.013 190.848C200.761 191.254 201.821 191.597 202.133 192.438C202.351 193.062 202.289 193.779 202.289 194.402C202.227 195.992 202.008 197.582 201.697 199.172C201.666 199.359 201.946 199.453 202.008 199.266C202.414 197.395 202.663 195.493 202.632 193.592C202.601 192.999 202.538 192.376 202.195 191.908Z'
            fill='#9C4725'
        />
        <path
            d='M204.783 190.287C204.346 189.664 203.474 189.352 202.85 189.009C202.663 188.916 202.507 189.165 202.694 189.29C203.38 189.664 204.409 190.038 204.689 190.817C204.97 191.597 204.845 192.594 204.752 193.405C204.533 195.057 204.066 196.709 203.629 198.299C203.567 198.486 203.879 198.58 203.941 198.393C204.44 196.46 205.095 194.465 205.157 192.438C205.157 191.753 205.188 190.88 204.783 190.287Z'
            fill='#9C4725'
        />
        <path
            d='M112.445 168.185C108.892 162.761 106.366 156.713 104.964 150.353C104.932 150.166 104.621 150.228 104.652 150.447C106.055 156.806 108.611 162.885 112.165 168.341C112.29 168.497 112.539 168.341 112.445 168.185Z'
            fill='#9C7968'
        />
        <path
            d='M112.819 172.518C111.104 171.115 109.452 169.65 107.831 168.154C107.675 168.029 107.457 168.247 107.613 168.372C109.203 169.899 110.886 171.365 112.601 172.736C112.757 172.861 112.975 172.643 112.819 172.518Z'
            fill='#9C7968'
        />
        <path
            d='M145.802 180.28C145.241 183.148 144.555 185.985 143.713 188.791C143.651 188.978 143.962 189.072 144.025 188.885C144.835 186.079 145.521 183.242 146.113 180.374C146.145 180.156 145.833 180.062 145.802 180.28Z'
            fill='#9C7968'
        />
        <path
            d='M173.796 184.894C173.765 184.707 173.453 184.707 173.484 184.894C173.702 187.232 173.328 189.508 172.362 191.659C172.268 191.846 172.549 192.002 172.642 191.815C173.609 189.601 174.014 187.263 173.796 184.894Z'
            fill='#9C7968'
        />
        <path
            d='M66.3073 145.552C63.221 151.6 60.914 158.022 59.4488 164.631C59.4176 164.818 59.6982 164.911 59.7606 164.724C61.257 158.115 63.5327 151.725 66.5878 145.708C66.6502 145.552 66.4008 145.396 66.3073 145.552Z'
            fill='#9C7968'
        />
        <path
            d='M117.121 131.493C113.567 130.214 109.826 129.591 106.054 129.653C105.867 129.653 105.867 129.965 106.054 129.965C109.795 129.934 113.505 130.526 117.027 131.804C117.214 131.867 117.308 131.555 117.121 131.493Z'
            fill='#9C7968'
        />
        <path
            d='M97.6372 197.208C92.1505 201.416 85.7598 204.347 78.995 205.75C78.808 205.781 78.8703 206.093 79.0886 206.061C85.8845 204.627 92.3064 201.697 97.7931 197.489C97.9489 197.333 97.7931 197.083 97.6372 197.208Z'
            fill='#9C7968'
        />
        <path
            d='M135.701 250.734C132.397 242.161 126.941 234.524 119.865 228.632C119.709 228.507 119.491 228.725 119.647 228.85C126.661 234.711 132.116 242.286 135.39 250.828C135.483 250.984 135.764 250.921 135.701 250.734Z'
            fill='#7D443B'
        />
        <path
            d='M117.901 103.498C116.498 102.968 114.908 102.906 113.474 103.405C112.819 103.623 112.196 103.966 111.635 104.402C110.762 105.057 110.045 105.961 108.86 105.899C108.673 105.899 108.673 106.21 108.86 106.21C110.45 106.304 111.417 104.745 112.695 104.059C114.285 103.249 116.124 103.155 117.776 103.81C118.025 103.872 118.088 103.561 117.901 103.498Z'
            fill='#B35E22'
        />
        <path
            d='M131.18 92.8055C129.871 89.6257 126.317 88.0982 123.106 89.3763C122.919 89.4387 123.012 89.7504 123.2 89.6881C126.223 88.4723 129.653 89.8439 130.9 92.899C130.962 93.0549 131.243 92.9926 131.18 92.8055Z'
            fill='#B35E22'
        />
        <path
            d='M114.627 94.8007C112.383 93.6784 109.64 94.0214 107.738 95.6736C107.582 95.7983 107.8 96.0165 107.956 95.8918C109.764 94.3643 112.352 94.0214 114.472 95.0813C114.659 95.1436 114.815 94.8942 114.627 94.8007Z'
            fill='#B35E22'
        />
        <path
            d='M217.409 189.196C216.879 189.259 216.38 189.29 215.975 189.352C215.912 189.664 215.975 189.944 216.318 190.038C216.785 190.163 217.533 190.1 217.876 189.726C218.001 189.601 218.094 189.414 218.126 189.196C217.876 189.165 217.627 189.196 217.409 189.196Z'
            fill='#9C4725'
        />
        <path
            d='M178.316 189.383C177.1 189.383 177.1 191.254 178.316 191.254C179.501 191.254 179.501 189.383 178.316 189.383Z'
            fill='#9C7968'
        />
        <path
            d='M79.7121 140.44C79.7121 140.315 79.5874 140.253 79.4627 140.315C78.0911 140.97 76.7506 141.718 75.4413 142.466C75.566 142.528 75.6595 142.591 75.7842 142.622C76.8441 141.998 77.9663 141.406 79.0574 140.845C78.2157 142.435 76.1894 145.552 75.3165 146.924C75.223 147.08 75.4725 147.236 75.5972 147.08C76.47 145.708 78.5899 142.435 79.4004 140.907C79.4316 142.154 79.4004 143.401 79.3068 144.648C79.4004 144.711 79.4939 144.742 79.6186 144.804C79.7121 143.339 79.7433 141.874 79.7121 140.44Z'
            fill='white'
        />
        <path
            d='M102.906 127.44C102.906 127.315 102.781 127.253 102.656 127.315C101.285 127.97 99.9442 128.718 98.6349 129.467C98.7596 129.529 98.8532 129.591 98.9779 129.622C100.038 128.999 101.16 128.407 102.251 127.845C101.409 129.435 99.3831 132.553 98.5103 133.924C98.4167 134.08 98.6661 134.236 98.7908 134.08C99.6637 132.709 101.783 129.435 102.594 127.908C102.625 129.155 102.594 130.402 102.5 131.649C102.594 131.711 102.688 131.742 102.812 131.805C102.906 130.339 102.937 128.905 102.906 127.44Z'
            fill='white'
        />
        <path
            d='M95.5795 155.31C95.5795 155.185 95.4549 155.123 95.3302 155.185C93.9585 155.84 92.618 156.588 91.3086 157.336C91.4333 157.398 91.5269 157.461 91.6516 157.492C92.7115 156.869 93.8338 156.276 94.9249 155.715C94.0832 157.305 92.0569 160.422 91.184 161.794C91.0905 161.95 91.3398 162.106 91.4645 161.95C92.3374 160.578 94.4572 157.305 95.2678 155.777C95.2989 157.024 95.2678 158.271 95.1743 159.518C95.2678 159.581 95.3613 159.612 95.486 159.674C95.6107 158.24 95.6107 156.775 95.5795 155.31Z'
            fill='white'
        />
        <path
            d='M129.278 145.646C129.278 145.521 129.154 145.459 129.029 145.521C127.657 146.176 126.317 146.924 125.008 147.672C125.132 147.735 125.226 147.797 125.35 147.828C126.41 147.205 127.533 146.612 128.624 146.051C127.782 147.641 125.756 150.758 124.883 152.13C124.789 152.286 125.039 152.442 125.163 152.286C126.036 150.914 128.156 147.641 128.967 146.113C128.998 147.36 128.967 148.607 128.873 149.854C128.967 149.917 129.06 149.948 129.185 150.01C129.31 148.545 129.31 147.08 129.278 145.646Z'
            fill='white'
        />
        <path
            d='M133.363 171.24C133.363 171.115 133.239 171.053 133.114 171.115C131.742 171.77 130.402 172.518 129.092 173.266C129.217 173.329 129.311 173.391 129.435 173.422C130.495 172.799 131.617 172.206 132.709 171.645C131.867 173.235 129.841 176.352 128.968 177.724C128.874 177.88 129.124 178.036 129.248 177.88C130.121 176.508 132.241 173.235 133.052 171.707C133.083 172.954 133.052 174.201 132.958 175.448C133.052 175.511 133.145 175.542 133.27 175.604C133.394 174.139 133.426 172.674 133.363 171.24Z'
            fill='white'
        />
        <path
            d='M56.3313 151.382C56.3313 151.257 56.2066 151.195 56.0819 151.257C54.7102 151.912 53.3698 152.66 52.0604 153.408C52.1851 153.471 52.2786 153.533 52.4033 153.564C53.4632 152.941 54.5855 152.348 55.6766 151.787C54.8349 153.377 52.8086 156.495 51.9357 157.866C51.8422 158.022 52.0916 158.178 52.2163 158.022C53.0892 156.65 55.2091 153.377 56.0196 151.85C56.0508 153.097 56.0196 154.344 55.9261 155.59C56.0196 155.653 56.1131 155.684 56.2378 155.746C56.3313 154.281 56.3625 152.847 56.3313 151.382Z'
            fill='white'
        />
        <path
            d='M165.878 184.021C165.878 183.897 165.753 183.834 165.628 183.897C164.257 184.551 162.916 185.299 161.607 186.048C161.732 186.11 161.825 186.172 161.95 186.203C163.01 185.58 164.132 184.988 165.223 184.426C164.381 186.016 162.355 189.134 161.482 190.505C161.389 190.661 161.638 190.817 161.763 190.661C162.636 189.29 164.755 186.016 165.566 184.489C165.597 185.736 165.566 186.983 165.473 188.23C165.566 188.292 165.66 188.323 165.784 188.386C165.909 186.92 165.909 185.486 165.878 184.021Z'
            fill='white'
        />
        <path
            d='M151.444 193.872C151.444 193.748 151.319 193.685 151.194 193.748C149.823 194.402 148.482 195.15 147.173 195.899C147.297 195.961 147.391 196.023 147.516 196.054C148.576 195.431 149.698 194.839 150.789 194.278C149.947 195.867 147.921 198.985 147.048 200.357C146.955 200.512 147.204 200.668 147.329 200.512C148.202 199.141 150.321 195.867 151.132 194.34C151.163 195.587 151.132 196.834 151.038 198.081C151.132 198.143 151.225 198.174 151.35 198.237C151.444 196.771 151.475 195.337 151.444 193.872Z'
            fill='white'
        />
        <path
            d='M72.8857 167.343C72.8857 167.218 72.761 167.156 72.6363 167.218C71.2646 167.873 69.9241 168.621 68.6148 169.369C68.7395 169.432 68.833 169.494 68.9577 169.525C70.0177 168.902 71.1399 168.309 72.231 167.748C71.3893 169.338 69.363 172.456 68.4901 173.827C68.3966 173.983 68.646 174.139 68.7707 173.983C69.6435 172.611 71.7634 169.338 72.574 167.811C72.6051 169.058 72.574 170.305 72.4804 171.552C72.574 171.614 72.6675 171.645 72.7922 171.707C72.8857 170.242 72.9169 168.808 72.8857 167.343Z'
            fill='white'
        />
        <path
            d='M95.5795 183.211C95.5795 183.086 95.4549 183.024 95.3302 183.086C93.9585 183.741 92.618 184.489 91.3086 185.237C91.4333 185.299 91.5269 185.362 91.6516 185.393C92.7115 184.77 93.8338 184.177 94.9249 183.616C94.0832 185.206 92.0569 188.323 91.184 189.695C91.0905 189.851 91.3398 190.007 91.4645 189.851C92.3374 188.479 94.4572 185.206 95.2678 183.678C95.2989 184.925 95.2678 186.172 95.1743 187.419C95.2678 187.482 95.3613 187.513 95.486 187.575C95.6107 186.11 95.6107 184.645 95.5795 183.211Z'
            fill='white'
        />
        <path
            d='M72.8857 195.213C72.8857 195.088 72.761 195.026 72.6363 195.088C71.2646 195.743 69.9241 196.491 68.6148 197.239C68.7395 197.302 68.833 197.364 68.9577 197.395C70.0177 196.772 71.1399 196.179 72.231 195.618C71.3893 197.208 69.363 200.325 68.4901 201.697C68.3966 201.853 68.646 202.009 68.7707 201.853C69.6435 200.481 71.7634 197.208 72.574 195.68C72.6051 196.927 72.574 198.174 72.4804 199.421C72.574 199.484 72.6675 199.515 72.7922 199.577C72.8857 198.112 72.9169 196.678 72.8857 195.213Z'
            fill='white'
        />
        <path
            d='M105.4 235.583C105.369 235.552 105.369 235.552 105.338 235.521C104.621 234.929 103.623 234.866 102.719 234.804C101.815 234.742 95.7986 234.742 87.7245 234.804C87.5063 234.804 87.3193 234.804 87.101 234.804C85.8853 234.804 84.6383 234.835 83.329 234.835C79.0893 234.866 74.5379 234.898 69.9553 234.929C69.1447 234.929 68.3654 234.929 67.5861 234.96C65.4039 234.991 63.2217 234.991 61.1018 235.022C50.4714 235.116 41.2127 235.209 38.2199 235.241C38.1264 235.241 38.0329 235.241 37.9394 235.241C37.69 235.241 37.4718 235.241 37.3471 235.241C35.4767 235.272 33.6997 235.396 32.6086 236.737C32.4215 236.955 32.2657 237.205 32.141 237.423C31.7045 238.14 31.4552 238.95 31.2369 239.761C31.0499 240.602 30.6758 241.694 30.9875 242.535C30.9875 242.566 30.9876 242.566 31.0187 242.598C31.3305 243.408 32.2033 243.564 33.0139 243.72C33.3256 243.782 36.3183 243.845 40.9009 243.907C41.0256 243.907 41.1192 243.907 41.2439 243.907C46.7929 243.969 54.5552 244.032 62.567 244.094C68.9577 244.125 75.5043 244.156 81.2715 244.187C82.799 244.187 84.2642 244.187 85.6359 244.187C87.5687 244.187 89.3768 244.187 90.9978 244.187C94.8634 244.187 97.6379 244.156 98.6667 244.094C99.4772 244.063 100.35 243.969 101.192 243.813C102.189 243.626 103.187 243.315 104.029 242.753C104.029 242.753 104.06 242.753 104.06 242.722C105.837 241.538 106.117 239.605 106.148 237.672C106.117 236.924 106.024 236.113 105.4 235.583Z'
            fill='#44479C'
        />
        <path
            d='M71.7629 238.981C71.5759 238.638 71.3888 238.264 71.2018 237.921C70.703 236.955 70.2978 235.926 69.986 234.929C69.269 232.778 68.8637 230.564 68.6455 228.351C68.0532 222.334 68.8014 216.286 68.4897 210.239C68.4273 208.773 68.2714 207.308 68.0844 205.874C67.4609 201.229 66.2451 196.647 64.8423 192.158C61.3508 181.153 56.4876 170.398 54.7107 159.082H0C0.935225 165.005 2.74331 170.834 4.73846 176.602C8.69758 187.98 13.4672 199.359 13.8413 211.299C14.0283 217.191 13.3425 223.114 14.0284 229.006C14.3713 231.936 15.0571 234.866 16.3976 237.641C17.5822 240.104 19.2968 242.504 21.9154 244.125H76.4079C74.3192 242.753 72.854 240.914 71.7629 238.981Z'
            fill='#F7E9E1'
        />
        <path
            d='M41.3053 244.094H40.9623C40.5882 243.346 40.2141 242.566 39.8712 241.756C37.0032 234.929 37.0656 227.665 36.9409 220.401C36.8785 216.473 36.6603 212.577 35.9433 208.711C35.2575 204.97 34.2287 201.26 33.1064 197.613C30.7372 190.038 28.1186 182.556 25.6246 175.043C23.8477 169.712 22.0708 164.381 20.2627 159.051H20.6056C22.6631 165.192 24.7206 171.333 26.7781 177.474C29.2408 184.863 31.8907 192.22 34.0729 199.671C35.164 203.38 36.0992 207.121 36.6291 210.956C37.1591 214.572 37.2214 218.188 37.2838 221.835C37.3149 225.67 37.3461 229.504 37.8761 233.308C38.3749 236.861 39.4348 240.291 41.0247 243.502C41.1182 243.72 41.2117 243.907 41.3053 244.094Z'
            fill='#E8DFDF'
        />
        <path
            d='M71.7626 238.981C71.5755 238.638 71.3885 238.264 71.2014 237.921C70.7026 236.955 70.2974 235.926 69.9857 234.929C69.2687 232.778 68.8634 230.564 68.6451 228.351C68.0528 222.334 68.801 216.286 68.4893 210.239C68.4269 208.774 68.271 207.308 68.084 205.874C67.4605 201.229 66.2448 196.647 64.8419 192.158C61.3504 181.153 56.4872 170.398 54.7103 159.082H51.7175C53.1827 163.446 54.1803 169.213 55.6455 173.578C58.3265 181.558 62.6909 191.565 65.2472 203.879C65.4031 204.659 65.5589 205.563 65.7148 206.529C66.3695 210.706 66.8682 216.318 66.6188 219.684C66.0889 226.356 66.3694 230.845 67.6476 234.96C68.0217 236.144 68.4581 237.298 69.0192 238.451C69.8609 240.259 70.9208 242.099 72.199 244.094H76.4075C74.3188 242.753 72.8537 240.914 71.7626 238.981Z'
            fill='#44479C'
        />
        <path d='M187.575 94.489H183.273L183.585 92.9303H187.887L187.575 94.489Z' fill='#C95838' />
        <path d='M244.873 94.489H240.54L240.851 92.9303H245.153L244.873 94.489Z' fill='white' />
        <path d='M192.188 94.489H189.756L190.037 92.9303H192.5L192.188 94.489Z' fill='#D1A38C' />
        <path d='M201.011 94.489H193.903L194.215 92.9303H201.323L201.011 94.489Z' fill='#CCB6B6' />
        <path d='M223.083 94.489H204.098L204.409 92.9303H223.363L223.083 94.489Z' fill='#632E29' />
        <path d='M238.825 94.489H225.763L226.075 92.9303H239.105L238.825 94.489Z' fill='#632E29' />
        <path
            d='M196.741 113.287H194.309L194.621 111.729H197.052L196.741 113.287Z'
            fill='#D1A38C'
        />
        <path
            d='M207.153 113.287H198.456L198.767 111.729H207.434L207.153 113.287Z'
            fill='#CCB6B6'
        />
        <path d='M237.299 113.287H210.239L210.551 111.729H237.61L237.299 113.287Z' fill='#632E29' />
        <path
            d='M192.594 113.287H188.261L188.573 111.729H192.875L192.594 113.287Z'
            fill='#C95838'
        />
        <path d='M243.751 113.35H239.449L239.73 111.791H244.063L243.751 113.35Z' fill='white' />
        <path d='M195.868 120.239H193.436L193.748 118.68H196.18L195.868 120.239Z' fill='#D1A38C' />
        <path d='M210.645 120.239H197.583L197.895 118.68H210.925L210.645 120.239Z' fill='#CCB6B6' />
        <path d='M226.45 120.239H213.731L214.043 118.68H226.762L226.45 120.239Z' fill='#632E29' />
        <path d='M246.775 120.239H228.133L228.445 118.68H247.087L246.775 120.239Z' fill='#632E29' />
        <path d='M191.659 120.239H187.357L187.638 118.68H191.971L191.659 120.239Z' fill='#C95838' />
        <path d='M252.573 120.239H248.24L248.552 118.68H252.854L252.573 120.239Z' fill='white' />
        <path d='M191.44 138.819H189.009L189.289 137.26H191.752L191.44 138.819Z' fill='#D1A38C' />
        <path d='M215.849 138.819H193.154L193.466 137.26H216.161L215.849 138.819Z' fill='#CCB6B6' />
        <path d='M230.128 138.819H217.378L217.689 137.26H230.408L230.128 138.819Z' fill='#632E29' />
        <path d='M241.412 138.819H231.811L232.091 137.26H241.724L241.412 138.819Z' fill='#632E29' />
        <path d='M253.445 138.819H243.188L243.5 137.26H253.725L253.445 138.819Z' fill='#632E29' />
        <path d='M186.95 138.819H182.617L182.929 137.26H187.231L186.95 138.819Z' fill='#C95838' />
        <path d='M259.555 138.819H255.222L255.534 137.26H259.836L259.555 138.819Z' fill='white' />
        <path
            d='M190.286 145.615H187.855L188.167 144.056H190.598L190.286 145.615Z'
            fill='#D1A38C'
        />
        <path d='M200.698 145.615H192L192.312 144.056H200.979L200.698 145.615Z' fill='#CCB6B6' />
        <path
            d='M225.232 145.615H202.538L202.818 144.056H225.544L225.232 145.615Z'
            fill='#632E29'
        />
        <path d='M233.089 145.615H226.916L227.228 144.056H233.4L233.089 145.615Z' fill='#632E29' />
        <path d='M246.368 145.615H234.896L235.177 144.056H246.68L246.368 145.615Z' fill='#632E29' />
        <path
            d='M185.797 145.615H181.464L181.775 144.056H186.078L185.797 145.615Z'
            fill='#C95838'
        />
        <path d='M253.009 145.615H248.676L248.987 144.056H253.321L253.009 145.615Z' fill='white' />
        <path d='M191.005 100.88H188.573L188.854 99.3212H191.316L191.005 100.88Z' fill='#D1A38C' />
        <path d='M215.415 100.88H192.72L193.031 99.3212H215.726L215.415 100.88Z' fill='#CCB6B6' />
        <path d='M242.941 100.88H218.688L218.968 99.3212H243.253L242.941 100.88Z' fill='#632E29' />
        <path d='M186.702 100.88H182.369L182.681 99.3212H186.983L186.702 100.88Z' fill='#C95838' />
        <path d='M249.113 100.88H244.78L245.092 99.3212H249.394L249.113 100.88Z' fill='white' />
        <path d='M195.181 126.786H192.718L193.03 125.227H195.461L195.181 126.786Z' fill='#D1A38C' />
        <path
            d='M202.444 126.786H196.896L197.176 125.227H202.756L202.444 126.786Z'
            fill='#CCB6B6'
        />
        <path
            d='M242.098 126.786H222.832L223.144 125.227H242.409L242.098 126.786Z'
            fill='#632E29'
        />
        <path
            d='M220.931 126.786H204.783L205.095 125.227H221.243L220.931 126.786Z'
            fill='#632E29'
        />
        <path d='M248.208 126.786H243.875L244.187 125.227H248.489L248.208 126.786Z' fill='white' />
        <path d='M189.663 107.115H185.36L185.672 105.556H189.974L189.663 107.115Z' fill='#C95838' />
        <path d='M190.848 126.692H186.546L186.858 125.133H191.16L190.848 126.692Z' fill='#C95838' />
        <path
            d='M183.678 132.522H179.345L179.656 130.994H183.958L183.678 132.522Z'
            fill='#C95838'
        />
        <path
            d='M200.326 75.3168C199.235 75.3168 198.175 74.8181 197.458 73.9452C196.71 73.0411 196.398 71.8253 196.616 70.6407L204.16 25.8433C204.503 24.0041 206.062 22.6636 207.87 22.6636H282.844C283.935 22.6636 284.995 23.1624 285.712 24.0352C286.46 24.9393 286.772 26.1551 286.554 27.3397L278.386 72.1371C278.043 73.9763 276.485 75.3168 274.676 75.3168H200.326ZM207.87 23.0065C206.218 23.0065 204.784 24.2223 204.472 25.9057L196.928 70.7342C196.71 71.8253 197.022 72.9476 197.707 73.7893C198.362 74.5687 199.328 75.0363 200.326 75.0363H274.676C276.329 75.0363 277.763 73.8205 278.074 72.1371L286.242 27.3397C286.46 26.2486 286.149 25.1263 285.463 24.2846C284.808 23.5053 283.842 23.0377 282.844 23.0377H207.87V23.0065Z'
            fill='#5154A6'
        />
        <path
            d='M283.716 23.2559L274.894 68.5832C274.582 70.1731 273.18 71.3265 271.559 71.3265H196.304C194.184 71.3265 192.563 69.3937 192.968 67.3051L193.841 62.8471L201.791 21.9777C202.102 20.3878 203.505 19.2344 205.126 19.2344H280.381C282.532 19.2344 284.122 21.1672 283.716 23.2559Z'
            fill='#401D1D'
        />
        <path
            d='M217.223 25.9056H214.792L215.072 24.3469H217.535L217.223 25.9056Z'
            fill='#A5C4D9'
        />
        <path d='M212.89 25.9058H207.933L208.245 24.347H213.17L212.89 25.9058Z' fill='#A5C4D9' />
        <path
            d='M231.968 25.9056H218.937L219.249 24.3469H232.279L231.968 25.9056Z'
            fill='#A5C4D9'
        />
        <path
            d='M248.116 25.9056H235.085L235.366 24.3469H248.428L248.116 25.9056Z'
            fill='#A5C4D9'
        />
        <path d='M263.86 25.9056H250.829L251.109 24.3469H264.171L263.86 25.9056Z' fill='#A5C4D9' />
        <path
            d='M217.814 45.6079H215.383L215.694 44.0492H218.126L217.814 45.6079Z'
            fill='#A5C4D9'
        />
        <path
            d='M225.733 45.6079H219.529L219.841 44.0492H226.014L225.733 45.6079Z'
            fill='#A5C4D9'
        />
        <path d='M243.72 45.6079H228.819L229.099 44.0492H244.032L243.72 45.6079Z' fill='#A5C4D9' />
        <path
            d='M251.265 45.6079H245.404L245.716 44.0492H251.577L251.265 45.6079Z'
            fill='#A5C4D9'
        />
        <path
            d='M258.309 45.6079H253.072L253.353 44.0492H258.621L258.309 45.6079Z'
            fill='#A5C4D9'
        />
        <path
            d='M213.201 45.6079H208.275L208.587 44.0492H213.513L213.201 45.6079Z'
            fill='#A5C4D9'
        />
        <path
            d='M210.052 64.2499H207.621L207.932 62.6912H210.364L210.052 64.2499Z'
            fill='#A5C4D9'
        />
        <path
            d='M234.462 64.2499H211.767L212.079 62.6912H234.774L234.462 64.2499Z'
            fill='#A5C4D9'
        />
        <path d='M248.739 64.2499H236.019L236.3 62.6912H249.05L248.739 64.2499Z' fill='#A5C4D9' />
        <path
            d='M256.283 64.2499H250.422L250.734 62.6912H256.595L256.283 64.2499Z'
            fill='#A5C4D9'
        />
        <path d='M205.47 64.25H200.545L200.825 62.6913H205.782L205.47 64.25Z' fill='#A5C4D9' />
        <path d='M215.944 34.198H213.513L213.824 32.6393H216.256L215.944 34.198Z' fill='#A5C4D9' />
        <path d='M224.798 34.198H217.659L217.971 32.6393H225.079L224.798 34.198Z' fill='#A5C4D9' />
        <path d='M254.165 34.198H228.041L228.321 32.6393H254.476L254.165 34.198Z' fill='#A5C4D9' />
        <path
            d='M211.331 34.1981H206.375L206.686 32.6394H211.612L211.331 34.1981Z'
            fill='#A5C4D9'
        />
        <path d='M215.351 56.4255H212.919L213.2 54.8668H215.663L215.351 56.4255Z' fill='#A5C4D9' />
        <path
            d='M227.603 56.4255H217.066L217.378 54.8668H227.914L227.603 56.4255Z'
            fill='#A5C4D9'
        />
        <path d='M251.7 56.4255H243.003L243.315 54.8668H251.981L251.7 56.4255Z' fill='#A5C4D9' />
        <path
            d='M241.133 56.4255H229.942L230.253 54.8668H241.414L241.133 56.4255Z'
            fill='#A5C4D9'
        />
        <path
            d='M265.449 56.4255H254.257L254.569 54.8668H265.729L265.449 56.4255Z'
            fill='#A5C4D9'
        />
        <path
            d='M210.707 56.4254H205.781L206.062 54.8667H211.018L210.707 56.4254Z'
            fill='#A5C4D9'
        />
        <path d='M214.885 40.277H209.928L210.24 38.7183H215.166L214.885 40.277Z' fill='#A5C4D9' />
        <path
            d='M210.302 51.1256H205.377L205.689 49.5669H210.614L210.302 51.1256Z'
            fill='#A5C4D9'
        />
        <path
            d='M179.906 75.6599L168.371 70.4538H136.979C135.95 70.4538 134.953 69.9862 134.298 69.1757C133.643 68.3651 133.363 67.3364 133.581 66.3076L134.953 59.2311C135.264 57.61 136.698 56.4254 138.351 56.4254H182.836C183.865 56.4254 184.862 56.893 185.517 57.7036C186.172 58.5141 186.452 59.5428 186.234 60.5716L184.862 67.6481C184.551 69.2692 183.117 70.4538 181.464 70.4538H178.69L179.906 75.6599ZM138.351 56.7683C136.854 56.7683 135.545 57.8594 135.233 59.3246L133.862 66.4012C133.675 67.3364 133.924 68.2716 134.516 69.0198C135.109 69.7368 136.013 70.1732 136.948 70.1732H168.403L179.407 75.1611L178.222 70.1732H181.402C182.898 70.1732 184.208 69.0822 184.52 67.617L185.891 60.5404C186.078 59.6052 185.829 58.67 185.237 57.9218C184.644 57.2048 183.74 56.7683 182.805 56.7683H138.351Z'
            fill='#5154A6'
        />
        <path
            d='M151.382 64.4372H144.555L145.085 61.7562H151.881L151.382 64.4372Z'
            fill='#A5C4D9'
        />
        <path
            d='M174.856 64.4372H155.497L155.996 61.7562H175.386L174.856 64.4372Z'
            fill='#A5C4D9'
        />
        <path
            d='M303.075 28.2751L304.946 23.0378H300.114C299.085 23.0378 298.087 22.5702 297.432 21.7597C296.778 20.9491 296.497 19.9204 296.716 18.8916L298.087 11.8151C298.399 10.194 299.833 9.0094 301.485 9.0094H345.94C346.968 9.0094 347.966 9.47702 348.621 10.2875C349.275 11.0981 349.556 12.1268 349.338 13.1556L347.966 20.2321C347.654 21.8532 346.22 23.0378 344.568 23.0378H315.264L303.075 28.2751ZM301.454 9.35232C299.958 9.35232 298.648 10.4434 298.337 11.9086L296.965 18.9852C296.778 19.9204 297.027 20.8556 297.62 21.6038C298.212 22.3208 299.116 22.7572 300.051 22.7572H305.32L303.543 27.7451L315.139 22.7572H344.506C346.002 22.7572 347.311 21.6661 347.623 20.201L348.995 13.1244C349.182 12.1892 348.932 11.2539 348.34 10.5058C347.748 9.78875 346.844 9.35232 345.908 9.35232H301.454Z'
            fill='#5154A6'
        />
        <path
            d='M337.927 16.4288H318.568L319.067 13.7789H338.457L337.927 16.4288Z'
            fill='#A5C4D9'
        />
        <path d='M315.576 16.335H307.751L308.219 13.8722H316.044L315.576 16.335Z' fill='#A5C4D9' />
        <path
            d='M279.601 86.5398L278.728 90.9665L263.734 167.905C263.422 169.463 262.05 170.617 260.429 170.617H260.273L277.201 82.8612C278.791 83.3288 279.851 84.8875 279.601 86.5398Z'
            fill='#B36242'
        />
        <path
            d='M308.563 200.482L299.366 200.326L298.712 196.834L308.033 196.99L308.563 200.482Z'
            fill='#E8DFDF'
        />
        <path
            d='M308.376 198.486C307.503 198.455 306.599 198.424 305.695 198.424C302.266 198.362 298.837 198.486 295.532 199.609C295.407 199.64 295.283 199.702 295.189 199.764C294.441 195.681 293.662 191.566 292.882 187.482C292.508 185.549 292.165 183.616 291.791 181.683C290.856 176.695 289.921 171.708 288.986 166.72C290.046 166.751 291.105 166.72 292.134 166.626C293.288 166.533 301.081 166.408 302.266 166.159C302.297 166.314 302.328 166.439 302.359 166.595C303.295 171.645 304.261 176.727 305.196 181.777C305.29 182.276 305.383 182.774 305.477 183.273C305.695 184.395 305.882 185.518 306.1 186.64C306.911 190.568 307.628 194.527 308.376 198.486Z'
            fill='#F28F68'
        />
        <path
            d='M304.354 176.945C300.987 176.82 297.651 176.789 294.315 176.82C293.193 176.82 292.071 176.851 290.949 176.883C291.292 178.815 291.666 180.748 292.04 182.681C293.069 182.587 294.097 182.525 295.126 182.463C297.713 182.276 300.301 182.12 302.888 181.964C303.699 181.933 304.478 181.87 305.289 181.839C304.946 180.187 304.665 178.566 304.354 176.945Z'
            fill='#401D1D'
        />
        <path
            d='M358.285 72.1061L343.134 152.099L338.521 176.135C338.24 177.6 336.962 178.66 335.466 178.66H335.029L264.607 178.036L285.244 70.0486C285.431 69.051 286.273 68.334 287.27 68.334H355.23C355.448 68.334 355.666 68.3652 355.885 68.3963C357.506 68.8016 358.628 70.3915 358.285 72.1061Z'
            fill='#F28F68'
        />
        <path
            d='M332.348 175.791L266.414 175.168L286.241 70.4847H352.174L332.348 175.791Z'
            fill='white'
        />
        <path
            d='M358.285 72.1059L338.521 176.166C338.552 177.755 337.866 179.096 335.466 178.691H335.029L355.885 68.4585C357.506 68.8014 358.628 70.3913 358.285 72.1059Z'
            fill='#B36242'
        />
        <path
            d='M308.719 200.855L295.439 200.7H282.72L282.065 197.146L294.784 197.177L308.189 197.333L308.719 200.855Z'
            fill='#B36242'
        />
        <path
            d='M308.376 198.486C307.503 198.455 306.599 198.424 305.695 198.424L301.923 178.348L304.604 178.41C304.822 179.532 305.945 185.518 306.163 186.64C306.911 190.568 307.628 194.527 308.376 198.486Z'
            fill='#B36242'
        />
        <path
            d='M305.913 80.6164H301.299L301.611 79.0265H306.256L305.913 80.6164Z'
            fill='#B8A59C'
        />
        <path
            d='M315.794 80.6164H307.752L308.063 79.0265H316.137L315.794 80.6164Z'
            fill='#632E29'
        />
        <path
            d='M333.127 80.6164H325.084L325.396 79.0265H333.439L333.127 80.6164Z'
            fill='#632E29'
        />
        <path
            d='M323.401 80.6164H317.852L318.164 79.0265H323.713L323.401 80.6164Z'
            fill='#CCB6B6'
        />
        <path d='M299.21 80.6172H296.124L296.435 79.0585H299.522L299.21 80.6172Z' fill='#C95838' />
        <path d='M338.177 80.5852H335.091L335.434 79.0265H338.489L338.177 80.5852Z' fill='white' />
        <path d='M304.012 93.6473H299.398L299.71 92.0886H304.355L304.012 93.6473Z' fill='#B8A59C' />
        <path d='M312.647 93.6473H305.851L306.163 92.0886H312.99L312.647 93.6473Z' fill='#632E29' />
        <path
            d='M328.515 93.6473H322.966L323.278 92.0886H328.827L328.515 93.6473Z'
            fill='#B8A59C'
        />
        <path d='M321.749 93.6473H313.987L314.33 92.0886H322.061L321.749 93.6473Z' fill='#632E29' />
        <path
            d='M297.122 93.6473H294.067L294.379 92.0886H297.433L297.122 93.6473Z'
            fill='#C95838'
        />
        <path d='M333.471 93.6786H330.416L330.728 92.1199H333.783L333.471 93.6786Z' fill='white' />
        <path
            d='M300.581 112.414H295.936L296.248 110.855H300.893L300.581 112.414Z'
            fill='#B8A59C'
        />
        <path d='M310.464 112.414H302.39L302.702 110.855H310.776L310.464 112.414Z' fill='#632E29' />
        <path d='M332.13 112.414H319.723L320.035 110.855H332.442L332.13 112.414Z' fill='#632E29' />
        <path d='M318.039 112.414H312.49L312.802 110.855H318.351L318.039 112.414Z' fill='#CCB6B6' />
        <path
            d='M293.411 112.415H290.356L290.668 110.856H293.754L293.411 112.415Z'
            fill='#C95838'
        />
        <path d='M337.18 112.414H334.094L334.405 110.855H337.491L337.18 112.414Z' fill='white' />
        <path d='M303.169 131.96H288.237L288.548 130.402H303.481L303.169 131.96Z' fill='#A5C4D9' />
        <path
            d='M298.898 151.257H283.997L284.309 149.667H299.241L298.898 151.257Z'
            fill='#A5C4D9'
        />
        <path
            d='M294.534 164.974H281.815L282.127 163.415H294.877L294.534 164.974Z'
            fill='#A5C4D9'
        />
        <path d='M298.65 138.164H287.146L287.458 136.574H298.961L298.65 138.164Z' fill='#A5C4D9' />
        <path d='M299.834 144.368H285.837L286.18 142.809H300.146L299.834 144.368Z' fill='#A5C4D9' />
        <path
            d='M297.246 158.708H283.248L283.591 157.118H297.557L297.246 158.708Z'
            fill='#A5C4D9'
        />
        <path d='M297.09 119.366H294.347L294.659 117.808H297.402L297.09 119.366Z' fill='#B8A59C' />
        <path
            d='M315.577 119.366H311.275L311.587 117.808H315.889L315.577 119.366Z'
            fill='#632E29'
        />
        <path d='M309.28 119.366H299.678L299.99 117.808H309.591L309.28 119.366Z' fill='#CCB6B6' />
        <path
            d='M292.507 119.273H289.452L289.764 117.714H292.819L292.507 119.273Z'
            fill='#C95838'
        />
        <path d='M320.222 119.366H317.167L317.479 117.808H320.534L320.222 119.366Z' fill='white' />
        <path d='M300.707 87.1007H297.62L297.932 85.542H301.018L300.707 87.1007Z' fill='#C95838' />
        <path d='M297.059 100.162H294.004L294.315 98.6038H297.37L297.059 100.162Z' fill='#C95838' />
        <path d='M299.865 106.117H296.81L297.122 104.558H300.177L299.865 106.117Z' fill='#C95838' />
        <path
            d='M291.573 125.663H288.487L288.799 124.136H291.885L291.573 125.663Z'
            fill='#C95838'
        />
        <path
            d='M290.949 80.5852H293.412L293.723 79.0265H291.292L290.949 80.5852Z'
            fill='#F28F68'
        />
        <path
            d='M290.015 86.8512H292.446L292.758 85.2925H290.326L290.015 86.8512Z'
            fill='#F28F68'
        />
        <path
            d='M288.549 93.6473H291.012L291.323 92.0886H288.892L288.549 93.6473Z'
            fill='#F28F68'
        />
        <path
            d='M287.613 99.9132H290.045L290.357 98.3545H287.925L287.613 99.9132Z'
            fill='#F28F68'
        />
        <path
            d='M286.305 106.117H288.736L289.048 104.558H286.616L286.305 106.117Z'
            fill='#F28F68'
        />
        <path d='M285.338 112.383H287.801L288.112 110.824H285.65L285.338 112.383Z' fill='#F28F68' />
        <path d='M283.905 119.148H286.336L286.648 117.62H284.216L283.905 119.148Z' fill='#F28F68' />
        <path
            d='M282.688 125.726H285.151L285.463 124.167H283.031L282.688 125.726Z'
            fill='#F28F68'
        />
        <path
            d='M281.753 131.992H284.185L284.496 130.433H282.065L281.753 131.992Z'
            fill='#F28F68'
        />
        <path
            d='M280.444 138.195H282.875L283.187 136.636H280.755L280.444 138.195Z'
            fill='#F28F68'
        />
        <path d='M279.477 144.461H281.94L282.252 142.903H279.789L279.477 144.461Z' fill='#F28F68' />
        <path
            d='M278.044 151.257H280.475L280.787 149.698H278.355L278.044 151.257Z'
            fill='#F28F68'
        />
        <path
            d='M276.703 158.708H279.135L279.446 157.149H277.015L276.703 158.708Z'
            fill='#F28F68'
        />
        <path
            d='M275.736 164.974H278.199L278.511 163.415H276.079L275.736 164.974Z'
            fill='#F28F68'
        />
        <path
            d='M300.705 57.9528C299.614 57.9528 298.554 57.454 297.837 56.5811C297.089 55.6771 296.777 54.4613 296.995 53.2766L298.024 44.7661C298.367 42.9268 299.926 41.5863 301.734 41.5863H358.003C359.094 41.5863 360.154 42.0851 360.871 42.958C361.62 43.862 361.931 45.0778 361.713 46.2624L360.061 54.773C359.718 56.6123 358.159 57.9528 356.351 57.9528H300.705ZM301.734 41.9292C300.082 41.9292 298.648 43.145 298.336 44.8284L297.307 53.339C297.089 54.4301 297.401 55.5524 298.086 56.3941C298.741 57.1734 299.707 57.641 300.705 57.641H356.351C358.003 57.641 359.437 56.4252 359.749 54.7418L361.401 46.2313C361.62 45.1402 361.308 44.0179 360.622 43.1762C359.967 42.3968 359.001 41.9292 358.003 41.9292H301.734Z'
            fill='#5154A6'
        />
        <path
            d='M359.157 43.2074L357.411 51.9673C357.131 53.4637 355.821 54.5236 354.325 54.5236H296.746C294.751 54.5236 293.255 52.7155 293.66 50.7516L295.375 41.9916C295.655 40.4952 296.965 39.4353 298.492 39.4353H356.071C358.035 39.4353 359.531 41.2434 359.157 43.2074Z'
            fill='#401D1D'
        />
        <path
            d='M311.461 44.9841H309.029L309.341 43.4565H311.773L311.461 44.9841Z'
            fill='#A5C4D9'
        />
        <path d='M319.38 44.9841H313.176L313.488 43.4565H319.66L319.38 44.9841Z' fill='#A5C4D9' />
        <path d='M336.12 44.9841H321.219L321.531 43.4565H336.432L336.12 44.9841Z' fill='#A5C4D9' />
        <path
            d='M349.775 44.9841H337.991L338.303 43.4565H350.087L349.775 44.9841Z'
            fill='#A5C4D9'
        />
        <path d='M307.19 45.0472H302.234L302.545 43.4885H307.471L307.19 45.0472Z' fill='#A5C4D9' />
        <path d='M310.775 49.941H308.343L308.655 48.3823H311.087L310.775 49.941Z' fill='#A5C4D9' />
        <path d='M318.694 49.941H312.49L312.802 48.3823H318.974L318.694 49.941Z' fill='#A5C4D9' />
        <path d='M335.434 49.941H320.533L320.845 48.3823H335.746L335.434 49.941Z' fill='#A5C4D9' />
        <path d='M349.089 49.941H337.305L337.617 48.3823H349.401L349.089 49.941Z' fill='#A5C4D9' />
        <path
            d='M306.473 49.7851H301.548L301.828 48.2264H306.785L306.473 49.7851Z'
            fill='#A5C4D9'
        />
        <path
            d='M381.323 57.5788H375.556C373.561 57.5788 372.314 56.3007 372.688 54.3367L373.561 49.6294C373.842 48.133 375.151 47.0731 376.678 47.0731L382.072 47.1043C384.067 47.1043 385.127 48.2266 384.753 50.1905L383.817 55.2719C383.506 56.7683 382.851 57.5788 381.323 57.5788Z'
            fill='#A5C4D9'
        />
        <path
            d='M383.131 48.9747L381.043 48.9124L378.892 51.0634L377.146 48.9435H375.182L377.832 52.1545L374.309 55.6772L376.398 55.7083L378.767 53.3079L380.731 55.7083H382.695L379.858 52.248L383.131 48.9747Z'
            fill='white'
        />
        <path
            d='M281.191 10.5057H276.296C274.301 10.5057 272.805 8.6976 273.179 6.73362L273.989 2.55628C274.27 1.05992 275.579 0 277.107 0H282.001C283.996 0 285.493 1.8081 285.119 3.77207L284.308 7.94943C283.996 9.44579 282.687 10.5057 281.191 10.5057Z'
            fill='#A5C4D9'
        />
        <path
            d='M282.375 7.91833L275.018 7.79364L275.485 6.26611L282.842 6.39082L282.375 7.91833Z'
            fill='white'
        />
        <path d='M175.603 92.9303L175.323 94.489H177.754L178.066 92.9303H175.603Z' fill='#F28F68' />
        <path
            d='M174.419 100.786H176.882L177.163 99.2275H174.731L174.419 100.786Z'
            fill='#F28F68'
        />
        <path d='M173.484 106.959H175.946L176.227 105.4H173.795L173.484 106.959Z' fill='#F28F68' />
        <path
            d='M172.611 113.256H175.043L175.354 111.697H172.923L172.611 113.256Z'
            fill='#F28F68'
        />
        <path d='M171.24 120.052H173.671L173.983 118.493H171.551L171.24 120.052Z' fill='#F28F68' />
        <path d='M170.367 126.349H172.798L173.11 124.791H170.647L170.367 126.349Z' fill='#F28F68' />
        <path
            d='M169.119 132.522H171.551L171.862 130.994H169.431L169.119 132.522Z'
            fill='#F28F68'
        />
        <path d='M168.246 138.819H170.678L170.99 137.26H168.527L168.246 138.819Z' fill='#F28F68' />
        <path
            d='M166.875 145.615H169.307L169.618 144.056H167.156L166.875 145.615Z'
            fill='#F28F68'
        />
        <path
            d='M158.334 90.3741L158.053 91.9329H160.485L160.796 90.3741H158.334Z'
            fill='#EDB04F'
        />
        <path d='M157.15 98.1989H159.612L159.893 96.6714H157.461L157.15 98.1989Z' fill='#EDB04F' />
        <path
            d='M160.485 107.146H162.917L163.229 105.587H160.766L160.485 107.146Z'
            fill='#EDB04F'
        />
        <path
            d='M159.581 113.443H162.043L162.324 111.885H159.892L159.581 113.443Z'
            fill='#EDB04F'
        />
        <path
            d='M158.209 120.239H160.672L160.952 118.681H158.521L158.209 120.239Z'
            fill='#EDB04F'
        />
        <path d='M157.336 126.536H159.768L160.08 124.978H157.648L157.336 126.536Z' fill='#EDB04F' />
        <path d='M154.998 135.515H157.43L157.742 133.956H155.31L154.998 135.515Z' fill='#EDB04F' />
        <path
            d='M154.125 141.812H156.557L156.869 140.253H154.406L154.125 141.812Z'
            fill='#EDB04F'
        />
        <path
            d='M152.754 148.608H155.186L155.498 147.049H153.035L152.754 148.608Z'
            fill='#EDB04F'
        />
        <path
            d='M158.739 101.161C158.615 101.348 158.521 101.503 158.396 101.69L158.241 101.971C158.209 102.033 158.116 102.22 158.085 102.252C157.96 102.314 157.991 102.22 157.929 102.127C157.866 102.033 157.835 101.909 157.804 101.815C157.742 101.628 157.679 101.441 157.648 101.254C157.586 101.067 157.305 101.129 157.337 101.348C157.399 101.628 157.492 101.909 157.617 102.189C157.679 102.376 157.773 102.626 157.991 102.626C158.241 102.626 158.334 102.376 158.459 102.22C158.646 101.909 158.833 101.628 159.02 101.316C159.114 101.161 158.864 101.005 158.739 101.161Z'
            fill='#EDB04F'
        />
        <path
            d='M153.533 130.464C153.408 130.651 153.315 130.807 153.19 130.994L153.034 131.275C153.003 131.337 152.91 131.524 152.878 131.555C152.754 131.618 152.785 131.524 152.723 131.431C152.66 131.337 152.629 131.213 152.598 131.119C152.536 130.932 152.473 130.745 152.442 130.558C152.38 130.371 152.099 130.433 152.13 130.651C152.193 130.932 152.286 131.213 152.411 131.493C152.473 131.68 152.567 131.93 152.785 131.93C153.034 131.93 153.128 131.68 153.253 131.524C153.44 131.213 153.627 130.932 153.814 130.62C153.907 130.464 153.658 130.308 153.533 130.464Z'
            fill='#EDB04F'
        />
        <path
            d='M173.859 84.7317C172.83 89.6572 171.801 94.6139 170.804 99.5395C166.938 118.618 163.353 137.759 160.017 156.962H159.706C160.579 151.85 161.514 146.706 162.418 141.593C165.847 122.577 169.557 103.623 173.547 84.7317H173.859Z'
            fill='#CCB6B6'
        />
    </svg>
);

export default forwardRef(Icon);
