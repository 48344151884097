import React, { useEffect, forwardRef, useState } from 'react';
import { Row } from 'simple-flexbox';
import TooltipComponent from 'components/tooltip/TooltipComponent';
import './checkbox.css';

function CheckboxComponent(
    {
        checked: checkedProp = false,
        className: classNameProp,
        errorMessage,
        label,
        name,
        onChange = () => {},
        noSelectableLabel,
        disabled,
        ...props
    },
    ref
) {
    const [checked, setChecked] = useState(checkedProp);
    const className = [
        'input-checkbox',
        classNameProp,
        checked ? 'input-checkbox-checked' : '',
        errorMessage ? 'input-checkbox-error' : '',
        disabled ? 'input-checkbox-disabled' : ''
    ].join(' ');
    const classNameContainer = errorMessage ? 'input-checkbox-container-error' : '';
    const classNameLabelContainer = [
        errorMessage ? 'input-checkbox-label-container-error' : '',
        disabled ? 'input-checkbox-label-disabled' : ''
    ].join(' ');

    useEffect(() => {
        if (checked !== checkedProp) {
            setChecked(checkedProp);
        }
    }, [checked, checkedProp]);

    function onClick() {
        if (disabled) {
            return;
        }
        onChange && onChange({ target: { checked: !checked } });
        setChecked((prev) => !prev);
    }

    return (
        <Row className={classNameContainer}>
            <div>
                <input
                    className='input-checkbox-hidden'
                    type='checkbox'
                    name={name}
                    checked={checked}
                    onChange={onChange}
                    ref={ref}
                    disabled={disabled}
                    {...props}
                />
                <Row vertical='center' className={classNameLabelContainer}>
                    <div onClick={onClick} className={className} />
                    <div
                        onClick={() => !noSelectableLabel && onClick()}
                        style={
                            noSelectableLabel || disabled
                                ? {}
                                : {
                                      cursor: 'pointer',
                                      fontSize: 20,
                                      letterSpacing: -0.4,
                                      color: '#424147'
                                  }
                        }
                    >
                        {label}
                    </div>
                </Row>
            </div>
            <div style={{ position: 'relative' }}>
                <div
                    style={{
                        position: 'absolute',
                        right: -25,
                        bottom: 4
                    }}
                >
                    <TooltipComponent type='error' message={errorMessage} />
                </div>
            </div>
        </Row>
    );
}

export default forwardRef(CheckboxComponent);
