import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Row } from 'simple-flexbox';
import Image from 'assets/img-no-data';

const useStyles = createUseStyles({
    container: {
        minHeight: 370,
        position: 'relative'
    },
    imageContainer: {
        marginBottom: -36,
        marginRight: 14,
        '@media (max-width: 626px)': {
            margin: '0px 0px 30px 30px'
        }
    },
    firstColumn: {
        flexGrow: 1,
        padding: 30,
        width: 317,
        paddingRight: 0,
        margin: 'auto 0px'
    },
    subLine: {
        width: 93,
        height: 3,
        backgroundColor: '#FFB282'
    },
    title: {
        fontWeight: 450,
        fontSize: 40,
        lineHeight: '40px',
        letterSpacing: '-0.473214px',
        color: '#696969',
        marginBottom: 19
    }
});

function NotIntegrated() {
    const theme = useTheme();
    const classes = useStyles({ theme });

    return (
        <Row horizontal='space-between' vertical='end' wrap className={classes.container}>
            <div vertical='center' className={classes.firstColumn}>
                <h1 className={classes.title}>This constituent has no Dataro scores</h1>
                <div className={classes.subLine} />
            </div>
            <Row horizontal='flex-end' className={classes.imageContainer}>
                <Image />
            </Row>
        </Row>
    );
}

export default NotIntegrated;
