const theme = {
    color: {
        background: '#E5E5E5',
        error: '#e20000',
        sidebarBackground: '#FFB282',
        sidebarIconColor: '#424147',
        backgroundWelcome: '#D6C6FF',
        backgroundWelcomeGradient:
            'radial-gradient(143.05% 119.89% at 44.63% 60.32%, #E8E1FF 0%, #C6B4FF 100%)',

        veryDarkGrayishBlue: '#373a47',
        lightGrayishBlue: '#F7F8FC',
        brightBlue: '#3498db',
        grey: '#F9F9F9',
        resultsIconColor: '#8564E7',
        chartsLine: '#9D82EB'
    },
    typography: {
        title: {
            fontWeight: 700,
            fontSize: 70,
            lineHeight: '68px',
            letterSpacing: '0.145833px'
        },
        title18: {
            fontWeight: 500,
            fontSize: 18,
            lineHeight: '26px',
            letterSpacing: '-0.5px'
        },
        title20: {
            // fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 20,
            lineHeight: '29px'
        },
        title30: {
            fontWeight: 500,
            fontSize: 30,
            lineHeight: '38px',
            letterSpacing: '-0.625px'
        }
    }
};

export default theme;
