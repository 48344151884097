import React from 'react';
import { Row } from 'simple-flexbox';
import { createUseStyles, useTheme } from 'react-jss';
import DataroLogo from 'assets/dataro-logo-med.png';

const useStyles = createUseStyles({
    text: {
        fontWeight: 390,
        fontSize: 15,
        lineHeight: '31px',
        letterSpacing: '-0.35px',
        color: '#5D5D5D'
    },
    link: {
        color: '#FFB282',
        marginLeft: 4,
        transition: 'all 0.4s ease-in-out',
        textDecoration: 'none',
        '&:hover': {
            color: '#8564e7'
        }
    }
});
function FooterComponent() {
    const theme = useTheme();
    const classes = useStyles({ theme });

    return (
        <Row horizontal='space-between' vertical='end'>
            <span className={classes.text}>
                Want to see more?
                <a
                    className={classes.link}
                    href='mailto:sales@dataro.io?Subject=NXT Upgrade Enquiry'
                >
                    Contact us
                </a>
            </span>
            <img height={45} src={DataroLogo} alt='Dataro' />
        </Row>
    );
}

export default FooterComponent;
