import { useState, useEffect, useRef } from 'react';
import JWT from 'jsonwebtoken';
import { AddinClient } from '@blackbaud/sky-addin-client';
import CURRENCIES from 'resources/currencies';
import Y_VAR_VALUES from './y_var_values.json';

const fetchWithRetry = async (url, options = {}, maxRetries = 3, baseDelay = 1000) => {
    let attempt = 0;

    while (attempt < maxRetries) {
        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                throw new Error(`Max retries reached. Failed to fetch: ${error.message}`);
            }
            const delay = baseDelay * Math.pow(2, attempt);

            await new Promise((resolve) => setTimeout(resolve, delay));
        }
    }
};

function DataroTile() {
    const [envValues, setEnvValues] = useState({});
    const [isIntegrated, setIsIntegrated] = useState();
    const [propensities, setPropensities] = useState();
    const [error, setError] = useState();
    const [userData, setUserData] = useState();
    const [connectUrl, setConnectUrl] = useState();
    const [connectionCompleted, setConnectionCompleted] = useState(false);
    const [isLoadingInit, setIsLoadingInit] = useState(true);
    const [isLoadingToken, setIsLoadingToken] = useState(true);
    const addinClient = useRef(null);

    const isLoading = isLoadingInit || isLoadingToken;

    useEffect(() => {
        addinClient.current = new AddinClient({
            callbacks: {
                init: (args) => {
                    setEnvValues({
                        envId: args.envId,
                        recordId: args.context.recordId
                    });
                    args.ready({
                        showUI: true,
                        title: 'Dataro Propensities'
                    });
                }
            }
        });
    }, []);

    useEffect(() => {
        async function getToken(addinClient) {
            if (addinClient) {
                setIsLoadingToken(true);
                try {
                    const token = await addinClient.getUserIdentityToken();
                    var decoded = JWT.decode(token);
                    setUserData(decoded);
                } catch (e) {
                    console.error(e.message);
                    setError(e.message);
                } finally {
                    setIsLoadingToken(false);
                }
            }
        }
        getToken(addinClient.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addinClient.current]);

    useEffect(() => {
        if (!envValues.envId || !envValues.recordId) {
            return;
        }
        async function fetchData() {
            setIsLoadingInit(true);
            const baseUrl = process.env.REACT_APP_API_URL;
            try {
                const result = await fetchWithRetry(`${baseUrl}`, {
                    method: 'POST',
                    body: JSON.stringify({
                        nxt_enviornmentId: envValues.envId,
                        nxt_context: envValues.recordId
                    })
                });
                const json = await result.json();
                const { is_integrated, client_status, currency, record_data } = json || {};
                const propensities = !record_data?.propensities
                    ? []
                    : record_data?.propensities
                          ?.filter((propensity) => {
                              const y_var_data = Y_VAR_VALUES[propensity.y_var];
                              return y_var_data !== undefined;
                          })
                          ?.map((propensity) => {
                              const currencySymbol = CURRENCIES[currency].symbol;
                              const y_var_data = Y_VAR_VALUES[propensity.y_var];
                              const name = y_var_data.name.replace('$', currencySymbol);
                              const action = y_var_data.action.replace('$', currencySymbol);
                              return {
                                  ...propensity,
                                  ...y_var_data,
                                  currencySymbol,
                                  action,
                                  name
                              };
                          });

                setPropensities(client_status === 'INTEGRATION_COMPLETED' ? propensities : []);
                setIsIntegrated(is_integrated);
            } catch (e) {
                console.error(e.message);
                setError(e.message);
            } finally {
                setIsLoadingInit(false);
            }
        }
        fetchData();
    }, [envValues.envId, envValues.recordId]);

    async function connectUser({ email, given_name, family_name, phone_number, client_name }) {
        const baseUrl = process.env.REACT_APP_AUTH_URL;
        try {
            const result = await fetchWithRetry(`${baseUrl}`, {
                method: 'POST',
                body: JSON.stringify({ email, given_name, family_name, phone_number, client_name })
            });
            const json = await result.json();
            const { success, message, url } = json || {};
            if (!success || success === 'false') {
                console.error(message);
                setError(message);
                return;
            }
            console.log(url);
            setConnectUrl(url);
            window.open(url, '_blank');
            setConnectionCompleted(true);
        } catch (e) {
            console.error(e.message);
            setError(e.message);
        }
    }

    return {
        addinClient: addinClient.current,
        envValues,
        error,
        isIntegrated,
        isLoading,
        connectionCompleted,
        propensities,
        userData,
        connectUser,
        setError,
        connectUrl
    };
}

export default DataroTile;
